import axios from 'axios';
const functionsV1Base = process.env.REACT_APP_FUNCTION_V1_URL || 'https://us-central1-watafan-33650.cloudfunctions.net/';
const rskApiBase = 'https://blockscout.com/rsk/mainnet/api?module=account&action=txlist&address=';
let envBase = location.href.includes('v2-prod') || location.href.includes('watafan.com') ? 'prod' : 'prod';
envBase = 'prod'
class ApiService {

    static async getPortFolio(address = '0xb5a21a2e6ea3cb90495a8b8f5329447d24a4a539', page = 1, perpage = 50) {
        const response = await axios.get(`${functionsV1Base}getUserDataV2?address=${address}&page=${page}&perpage=${perpage}&user=${address}`);

        return response.data;
    }

    static async getWatacard(address, id) {
        if (address == null) {
            address = '0xb5a21a2e6ea3cb90495a8b8f5329447d24a4a539';
        }
        const resp = await axios.get(`${functionsV1Base}getWatacard?address=${address}&assetId=${id}`)
        return resp.data;

    }
    static async getOpenRaffles() {
        return axios.get(`${functionsV1Base}getOpenRaffles?env=${envBase}`)
    }
    static async getProgrammedRaffles() {

    }
    static async getMarketPlace(page = 1, perpage = 50, refecth = false) {
        if (refecth) {
            await axios.get(`${functionsV1Base}syncMarketData`)

        }
        return axios.get(`${functionsV1Base}getMarketDataV2?&page=${page}&perpage=${perpage}`)
    }
    static async getHomeData() {
        return axios.get(`${functionsV1Base}getHomeData`)
    }

    static async getTxList(address){
        const resp = await axios.get(`${rskApiBase}${address}`);
        return Promise.resolve(resp.data.result)
    }
    static async getBaulBalance(){
        return axios.get(`${functionsV1Base}getBaulBalance`)
    }
    static async getFileType(url){
        return axios.get(`${functionsV1Base}minechecker-dev-mimecheck?url=${url}`)
    }

}

export default ApiService;