import React, { useState, useEffect } from 'react'
import ApiService from '../../services/ApiService';
import UserService from '../../services/UserService';
import Watacard from '../../components/Watacard/Watacard'
import Container from '../../common/Container/Container';
import HomeLoader from '../../components/HomeLoader/HomeLoader';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from "react-router-dom";

const Marketplace = function Marketplace(props) {
    const [watacards, setWatacards] = useState([])
    const [noData, setNoData] = useState(false)
    const { owner } = useParams();
    const [userOwner, setUserOwner] = useState();
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = (refecth =false) => {

        ApiService.getMarketPlace(1,50,refecth)
            .then(watacards => {
                if (!owner) {
                    setNoData(!watacards.data.data.length)
                    setWatacards(watacards.data.data)

                }else {
                    setNoData(!watacards.data.data.filter(wa => wa.owner == owner).length)
                    setWatacards(watacards.data.data.filter(wa => wa.owner == owner))
                    UserService.getUserData(owner)
                    .then((res)=> {
                        setUserOwner(res.data)
                    })
                    
                    
                }

                    
            })    
    }
    const refresh = (refecth=true)=>{
        return new Promise((resolve, reject) => {

            ApiService.getMarketPlace(1, 50, refecth)
                .then(watacards => {
                    if (!owner) {
                        setNoData(!watacards.data.data.length)
                        setWatacards(watacards.data.data)

                    } else {
                        setNoData(!watacards.data.data.filter(wa => wa.owner == owner).length)
                        setWatacards(watacards.data.data.filter(wa => wa.owner == owner))
                        UserService.getUserData(owner)
                            .then((res) => {
                                setUserOwner(res.data)
                            })


                    }

                    resolve(true)
                })

        })
        
    }
    const fallBack = () => {
        const fallback = noData
        const loader = watacards.length
        if(!fallback && !loader){
            return(
                <HomeLoader/>
            ) 
        }else if(fallback){
            return(
                <Row className="px-lg-3 d-flex flex-row justify-content-center align-items-center" style={{minHeight:"550px"}}>
                <div style={{maxWidth:"600px",fontSize:'20px'}}>
                    <h1 className="text-center ">Vaya...</h1>
                    {/* <div className="d-flex flex-row justify-content-center align-items-center m-3">
                        <img className="position-relative mx-auto" style={{width:"120px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACSElEQVR4nO3cvW4TQRSG4TMmaYkNE8e5BGgQNNSImiQNRrmUdOmgR4rEJQQoEC3B3Aeid5AXbGi9ZCnSrfjRzB7xTdj3aa05WvuVz8q2ZDMAAAAAAAAA6IPgOWx6MJtOdh6ces4szef5bHr69uErr3kDr0GHjz5Ea+y517xSNSGcHO6d7XjNcwvw45qdmNnYa17BYj0YvPAa5hJgejCbWtM89ph1FYTG9p/svXd5vp0D9GX1tHmtos4BerR62lxWUacAfVs9bR6rKDtAX1dPW9dVlB2gx6unrdMqygrQ99XT1mUVJQdg9fxa7ipKDsDq+a2sVbSReuDW7bt/eat9Sx15pYxu3vvTw/v2Jm2e21cRyEMAMQKIEUCMAGIEECOAWPLngH+laRqrFue2WlZW1+usGRubmzYcRovbEwvB9edvN8UGqBbnVi3mnWbU67VVi7kFM4vjXZ8Lc1bsClotK7dZS8dZ3ooN4KnM5XOp2ADDUXSbtXXDb5a3Yu8BcXtiwcyWq8rqdYeb8ChajBPfi3NUbIAQgsXxbrE3Ty/FrqC+IIAYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGADECiBFAjABiBBAjgBgBxAggRgAxAogRQIwAYgQQI4AYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGALGcAN/dr+I/ETL+MC8nwFnGmV5ozN6lnkkOEJrBUTD7knquB76Gi3CUeig5wPHT6x/Xob5jZi+NdWR2+Rq8Dhfh/vGzrU/qiwEAAAAAAACAMv0EUIV+3iF9d7UAAAAASUVORK5CYII="></img>
                    </div> */}
                    <div className="text-center" style={{fontFamily: 'Varela'}}>
                       <span>En este momento no hay watacards a la venta por aqui...</span><span className="d-block" > Vuelve mas tarde!&#129311;</span> 
                    </div>
                </div>
            </Row>
            )
        }else{
            return 
        }
    }

    return (
        <Container fluid title={owner ? `Mercado de ${userOwner ? `(${userOwner.displayName || userOwner.email})` : ''}` : "Mercado"} subtitle={owner} onHandleRefresh={refresh}>
            <Row style={{minHeight: "550px"}}>
                {watacards.map((watacard, i) => {
                    return (
                        <Col lg={2} md={3} sm={4} xs={12} key={i}>
                            <Watacard watacardData= {watacard}/>
                        </Col>
                    )
                })
            }
            {fallBack()}

            </Row>
        </Container>

    )
}



export default Marketplace
