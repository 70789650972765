import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button' 
import {Link} from 'react-router-dom'
export default function SecondSlide() {
    return (
        <div style={{maxHeight: 600}}>
            <Row>
            <div style={window.screen.width >700?{width:'100vw',height:'600px', position:"absolute", zIndex:100}:{width:'100vw',height:'600px', position:"absolute", zIndex:100, marginTop:230}}>
                <Row>
                {window.screen.width > 700 &&
                    <Col className="d-flex justify-content-center py-5">
                        <img src="/assets/img/app_2.png" alt="watafanapp" />
                    </Col>
                }
                <Col className="d-flex justify-content-center py-5 mx-auto align-items-center">
                    <div className="p-4" style={{width:"500px"}}>
                        <h1 className="text-light text-end">COMPARTIENDO EMOCIONES COLECCIONABLES</h1>
                        <span className="text-light d-block text-end">Puedes coleccionarlos, comprarlos o venderlos en el marketplace.</span>
                        {/* <span className="text-light d-block text-end">¡Los beneficios los donamos a ONGs y otras acciones sociales en línea con los Objetivos de Desarrollo Sostenible!</span> */}
                        <Link className="float-end" to={{ pathname: `marketplace/` }}>
                            <Button size="lg" variant="info" className=" my-3 text-light">Ver Market</Button>
                        </Link>
                        </div>
                </Col>
                </Row>
            </div>


                <div style={{height:'700px', position:"absolute", backgroundColor: "black", opacity:0.5}}></div>
                
                {window.screen.width > 767?
                <video   playsInline autoPlay muted loop style={{objectFit:"cover", height:'600px'}}>
                    <source type="video/mp4"  src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/videos_home%2F1056266150-hd.mp4?alt=media&token=a747a6a4-3e0d-46fb-8a17-8bfe9b85a181"/>
                </video>
                :<img src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/images_2%2F1056266150-hd_Moment.jpg?alt=media&token=22040ddb-1f02-4d5b-b815-7c43ce424fd5" style={{objectFit:"cover", height:'600px'}}/>
            }
            </Row>
        </div>
    )
}
