
export const imApplicant = (raffle, address) =>{
    const applicantsKeys = Object.keys(raffle).filter((r)=>{return r.includes('applicant')});
    let applicants = [];
    applicantsKeys.forEach((aK)=>{
        if (raffle[aK].address === address) {
            applicants.push(raffle);
        }
        
    });
    return applicants.length > 0 || false;
}
export const getApplications = (raffle, address) => {
    const applicantsKeys = Object.keys(raffle).filter((r) => {return r.includes('applicant') });
    let applicants = [];
    applicantsKeys.forEach((aK) => {
        if (raffle[aK].address === address) {
            applicants.push(raffle);
        }

    });
    return applicants.length;
}

export const parseValue= (price)=>{
    return parseFloat(price).toFixed(5);
}
export const getApplicants= (raffle)=>{
    return Object.keys(raffle).filter((r)=>{return r.includes('applicant')}).length
}
