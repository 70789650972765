import React from 'react'


export default function Feed({children}) {

    return (
        <div className="container">
            <div className="p-lg-5 px-sm-0 mx-lg-2 mx-sm-0  mb-3">
                <div className="row">
                    {children}
                </div>
            </div>
        </div>
    )
}
