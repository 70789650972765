import React, {useEffect} from 'react'
import {useWallet} from '../../context/WalletContext';
import SessionStorageService from '../../services/SessionStorageService';
import SyncService from '../../services/SyncService';
import ApiService from '../../services/ApiService';
var interval
export default function TransactionProvider({children}) {
    const context = useWallet();
    
    useEffect(() => {
        
        if (context.state.address ) {
             SessionStorageService.getTransaction(context.state.address)
                 .then((res) => {
                 const last_nonce = SessionStorageService.getNonce();
                 const transactionType = res.type
                 interval = setInterval(() => {
                     
                     ApiService.getTxList(context.state.address)
                         .then(result => {
                             console.log(result[0]);
                             if (result.find(result => result.nonce == last_nonce)) {
                                
                                 const actionsMapper = {
                                     'sell': SyncService.syncSell,
                                     'buy': SyncService.syncBuy,
                                     'raffle': SyncService.syncRaffle,
                                     'cancel': SyncService.syncSell,
                                     'updatePrice': SyncService.syncSell
                                 }
                                 const action = actionsMapper[transactionType];
                                 action(context.state.address)
                                     .then(() => {
                                         SessionStorageService.removeTransaction(context.state.address);
                                         SessionStorageService.removeNonce();
                                         clearInterval(interval)
                                     })

                             }


                         })


                 }, 5000);

             })
            
        }   
    }, [context])
    return (
        <React.Fragment>{children}</React.Fragment>
    )
}
