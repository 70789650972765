import axios from 'axios';
import randomstring from 'randomstring';
const functionsV1Base = process.env.REACT_APP_FUNCTION_V1_URL || 'https://us-central1-watafan-33650.cloudfunctions.net/';
const envBase = location.href.includes('v2-prod') || location.href.includes('watafan.com') ? 'prod': 'prod'
class RaffleService{

    static async setApplicant(fannel, address, ownerAddress, uid, participations, env = envBase){
        const txDigit = randomstring.generate({
            length: 12,
            charset: 'alphabetic'
        });
        return axios.post(`${functionsV1Base}setApllicant?address=${address}&fannel=${fannel}&uid=${uid}&p=${participations}&env=${env}&add=${ownerAddress}&transaction=${txDigit}`);
    }
    static  getApplicantData(fannel, address, ownerAddress, uid, participations, env = envBase) {
        const txDigit = randomstring.generate({
            length: 12,
            charset: 'alphabetic'
        });
        return { address, fannel, uid, participations, env, ownerAddress, txDigit };
    }
}

export default RaffleService;
