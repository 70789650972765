import React, { useState } from 'react'
import LocalStorageService from '../services/LocalStorageService'

const WalletContext = React.createContext();
const baseState = { provider: null, connect: () => { }, disconnect: () => { }, address: null, user: null, noUSer: null};
function walletReducer(state, action) {
    switch (action.type) {
        case 'setProvider': {
            const connect = action.connect || new Function();
            const disconnect = action.disconnect || new Function();
            let address = action.address || null;
            const user = action.user || null;
            address = address? address.toLocaleLowerCase() :null
            const noUser = action.noUser || null;
            const baul = action.baul || null;
            //TODO: sacar de acab esto actualiza el usurio
            const localUser = LocalStorageService.getUser()
            if (user && localUser && (user.displayName !== localUser.displayName || user.email !== localUser.email)) {
                LocalStorageService.setUser(user);
                }

            // esto actualiza la imagen
            if (user && localUser && (user.displayName === localUser.displayName && user.email === localUser.email && user.photoUrl !== localUser.photoUrl)) {
                LocalStorageService.setUser(user);
            }
            return { provider: action.data, connect, disconnect, address, user, noUser, baul}
        }
        case 'disconnect': {
            return { ...baseState }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}
function WalletProvider({ children }) {
    const [state, dispatch] = React.useReducer(walletReducer, { ...baseState })
    const value = { state, dispatch }

    return <WalletContext.Provider value={{ ...value }}>{children}</WalletContext.Provider>
}
function useWallet() {
    const context = React.useContext(WalletContext)
    if (context === undefined) {
        throw new Error('useWallet must be used within a WalletProvider')
    }
    return context
}
export { WalletProvider, useWallet }