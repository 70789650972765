import React from 'react'
import { default as BootstrapButton }  from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
export default function Button(props) {
    const children = props.children;
    const disabled = props.disabled || props.loading
    return (
        <BootstrapButton {...props} disabled={disabled}>
            {props.loading
                ? <React.Fragment>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                 Procesando...
                    </React.Fragment>
                : children
            }

        </BootstrapButton>
    )
}
