import React, { useEffect, useState } from 'react'
import Container from '../../common/Container/Container';
import ApiService from '../../services/ApiService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RaffleCard from '../../components/RaffleCard/RaffleCard'
import LoadingRaffleCard from '../../components/LoadringRaffleCard/LoadingRaffleCard';


export default function Raffles() {
    const [noData, setNoData] = useState(false)
    const [raffles, setRaffles] = useState([]);
    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = () => {

        ApiService.getOpenRaffles()
            .then(raffles => {
                setNoData(!raffles.data.length)
                setRaffles(raffles.data);
            });


    }
    const handleRefresh= () => {
        
    }
    
    const fallBack = () => {
        const fallback = noData
        const loader = raffles.length
        if(!fallback && !loader){
            return(
                <LoadingRaffleCard/>
            ) 
        }else if(fallback){
            return(
                <Row className="px-lg-3 d-flex flex-row justify-content-center align-items-center" style={{minHeight:"550px"}}>
                <div style={{maxWidth:"600px",fontSize:'20px'}}>
                    <h1 className="text-center ">Vaya...</h1>
                    {/* <div className="d-flex flex-row justify-content-center align-items-center m-3">
                        <img className="position-relative mx-auto" style={{width:"120px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACSElEQVR4nO3cvW4TQRSG4TMmaYkNE8e5BGgQNNSImiQNRrmUdOmgR4rEJQQoEC3B3Aeid5AXbGi9ZCnSrfjRzB7xTdj3aa05WvuVz8q2ZDMAAAAAAAAA6IPgOWx6MJtOdh6ces4szef5bHr69uErr3kDr0GHjz5Ea+y517xSNSGcHO6d7XjNcwvw45qdmNnYa17BYj0YvPAa5hJgejCbWtM89ph1FYTG9p/svXd5vp0D9GX1tHmtos4BerR62lxWUacAfVs9bR6rKDtAX1dPW9dVlB2gx6unrdMqygrQ99XT1mUVJQdg9fxa7ipKDsDq+a2sVbSReuDW7bt/eat9Sx15pYxu3vvTw/v2Jm2e21cRyEMAMQKIEUCMAGIEECOAWPLngH+laRqrFue2WlZW1+usGRubmzYcRovbEwvB9edvN8UGqBbnVi3mnWbU67VVi7kFM4vjXZ8Lc1bsClotK7dZS8dZ3ooN4KnM5XOp2ADDUXSbtXXDb5a3Yu8BcXtiwcyWq8rqdYeb8ChajBPfi3NUbIAQgsXxbrE3Ty/FrqC+IIAYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGADECiBFAjABiBBAjgBgBxAggRgAxAogRQIwAYgQQI4AYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGALGcAN/dr+I/ETL+MC8nwFnGmV5ozN6lnkkOEJrBUTD7knquB76Gi3CUeig5wPHT6x/Xob5jZi+NdWR2+Rq8Dhfh/vGzrU/qiwEAAAAAAACAMv0EUIV+3iF9d7UAAAAASUVORK5CYII="></img>
                    </div> */}
                    <div className="text-center" style={{fontFamily: 'Varela'}}>
                       <span>En este momento no hay sorteos abiertos...</span><span className="d-block" > Vuelve mas tarde!&#129311;</span> 
                    </div>
                </div>
            </Row>
            )
        }else{
            return 
        }
    }
    
    return (
        <Container fluid title="Sorteos">
                <Row className="px-lg-5 py-2" style={{minHeight: "550px"}}>
                        {raffles.map((raffle, i) => {
                            return (
                                <Col  xs={12} sm={12} md={6} lg={6} xl={4}  key={i} >
                                    <RaffleCard raffle = {raffle}/>
                                </Col>
                            )
        
                        })
                    }
                    {fallBack()}
                </Row>
        </Container>
    )
}
