import React, {useEffect} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    

} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Layout from '../Layout/Layout'
import TransactionProvider from '../../common/TrnasactionProvider/TransactionProvider';
import Portfolio from '../../pages/Portfolio/Portfolio';
import Watacard from '../../pages/Watacard/Watacard';
import Home from '../../pages/Home/Home';
import Raffles from '../../pages/Raffles/Raffles';
import Marketplace from '../../pages/Marketplace/Marketplace';
import Register from '../../pages/Register/Register';
import Payment from '../../pages/Payment/Payment';
import Raffle  from '../../pages/Raffle/Raffle';
import NotFound from '../../pages/NotFound/NotFound';
import Profile from '../../pages/Profile/Profile';
import ScrollToTop from "../../hooks/ScrollToTop";






export default function RouterWrapper() {
    
    return (
        <Router>
            <ScrollToTop />
            <Layout>
                <TransactionProvider>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        
                        <Route exact path="/portfolio">
                            <Portfolio />
                        </Route>
                        <Route exact path="/portfolio/:id">
                            <Watacard />
                        </Route>
                        <Route exact path="/marketplace">
                            <Marketplace />
                        </Route>
                        <Route exact path="/marketplace/:id">
                            <Watacard market={true} />
                        </Route>
                        <Route exact path="/marketplace/owner/:owner">
                            <Marketplace />
                        </Route>
                        <Route exact path="/raffles">
                            <Raffles />
                        </Route>
                        <Route exact path="/raffles/:title">
                            <Raffle />
                        </Route>
                        <Route exact path="/raffles/:id">
                            <Portfolio />
                        </Route>
                        <Route exact path="/profile">
                            <Profile />
                        </Route>
                        <Route exact path="/profile/new">
                            <Register />
                        </Route>
                        <Route exact path="/pay">
                            <Payment />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </TransactionProvider>
            </Layout>
        </Router>
    )
}
