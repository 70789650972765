import {React, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {priceParser} from '../../utils/watacard';
import Button from '../../common/Button/Button';
import ApiService from '../../services/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useLocation, useHistory } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {parseValue} from '../../utils/raffle'
import { Row } from 'react-bootstrap';
import Countdown from 'react-countdown'


export default function Watacard({watacardData, raffle}) {
let watacardImg = watacardData.image
const watacardTitle = watacardData.title
const watacardPrice = priceParser(watacardData.next_price);
const [type, setType] = useState();
const location =useLocation();
const history = useHistory();
// const [show, setShow] = useState(false);
//   const target = useRef(null);

useEffect(() => {
    ApiService.getFileType(watacardImg)
    .then((res)=> {
        setType(res.data.type)
    })
},[])
const renderPrice = () =>{
    if (raffle) {
        if (watacardData.priceBtc == 0) {
            return (

                <span className="text-secondary my-2 d-block">Free</span>
            )
        } else {
            return (

                <span className="text-secondary my-2 d-block">{parseValue(watacardData.priceBtc)} RBTC</span>
            )
        }
        
    }
    if(watacardPrice && location.pathname !="/portfolio"){
        return(
        <span className="text-secondary my-2 d-block">{watacardPrice} RBTC</span>
        )
    }
}
const displayRoute = () =>{
    if(watacardPrice && location.pathname !="/portfolio"){
        return {pathname: `/marketplace/${watacardData.assetId}`}
    }else{
        return {pathname:`/portfolio/${watacardData.assetId}`}
    }
}
const renderPremiumTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Este Watacard fue enviado directamente por el idolo
    </Tooltip>
  );
const renderSaleTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      En venta
    </Tooltip>
  );

const onApply = () => (
    history.push(`/raffles/${watacardData.title.split(' ').join('_').split('?').join('+')}`, { apply: (watacardData.priceBtc == 0 ||false)})
);

const onDetails= () => {
    history.push(`/raffles/${watacardData.title.split(' ').join('_').split('?').join('+')}`)
};
const onBuy = () => {
    history.push(displayRoute().pathname, { apply: (watacardPrice && location.pathname != "/portfolio")})

}
return (

    <div className="bg-light border rounded d-flex flex-column p-1 mx-auto my-4" style={{width: "215px"}}>
        <div className="p-0 m-0" style={{backgroundColor:"light", height:"270px", width:"200px", textAlign:"center", verticalAlign: "middle"}}>
            <Row className="position-absolute m-1">
                {location.pathname=="/portfolio" && watacardData.status == 1 &&
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderSaleTooltip}
                    >
                        <div style={{width:"23px", height:"23px", fontSize:"10px"}} className="text-light rounded-circle bg-secondary d-flex justify-content-center align-items-center"><FontAwesomeIcon  icon={["fas", "tags"]}/></div>
                    </OverlayTrigger>
                }
                {watacardData.last_price == 0 &&
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderPremiumTooltip}>
                        <div style={{width:"23px", height:"23px", fontSize:"13px"}} className="text-light rounded-circle bg-secondary d-flex justify-content-center align-items-center"><FontAwesomeIcon  icon={["fas", "star"]}/></div>
                    </OverlayTrigger>
                }
            </Row>
            <span style={{height: "100%", display:"inline-block", verticalAlign:"middle"}}></span>
            {(type === 'image'  )
                ? (watacardData.title === 'WIS?V' ? <video src={watacardImg} autoPlay loop muted playsInline style={{ maxWidth: "100%", maxHeight: "100%" }} > </video> : <img className="m-0" src={watacardImg} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} /> )
                : <video src={watacardImg} autoPlay loop muted playsInline  style={{ maxWidth: "100%", maxHeight: "100%" }} > </video>
            }
        </div>
        <div className="container p-1">
            <span className="text-dark my-2 d-block" style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{watacardTitle}</span>
            {//TODO: refactor para esto porfis
            }
            {raffle && <span className="text-dark my-2 d-block text-end"><Countdown date={watacardData.periode} /></span> }
            
            {renderPrice()}
            {raffle? 
                <Button size="sm" variant="info" className="col-5 m-1"  onClick={onApply}>Participar</Button>
                : <Button size="sm" variant="info" className="col-5 m-1" disabled={watacardData.status == 1 && location.pathname == "/portfolio"} onClick={onBuy}>{location.pathname == "/portfolio" ? `${watacardData.status == 1 ? 'En venta' : 'Vender'}`:'Comprar'}</Button>
            }
            {raffle?
                <Button size="sm" variant="outline-secondary" className="col-5 m-1" onClick={onDetails}>Detalles</Button>
                : <Link to={displayRoute()}>
                    <Button size="sm" variant="outline-secondary" className="col-5 m-1">Detalles</Button>
                </Link>
            }
            
        </div>
    </div>
)
}
