import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Privacy from '../privacy/Privacy'

export default function Footer() {
    const [showLegal, setShowLegal] = useState(false);
    return (
        <> 
        <footer className="text-secondary">
            <div className="container py-5">
                <div className="row py-4">
                    <div className="col-lg-4 col-md-6 mb-lg-0">
                        <h6 className="text-uppercase font-weight-bold mb-4">Comunidad</h6>
                        <p className="text-muted mb-4">Conecta con otros fans de la comunidad alrededor del mundo</p>
                        <div className="pb-5 mt-3 p-1 d-flex flex-row justify-content-evenly">
                            <a href="https://t.me/joinchat/C0ufakXXd7AndfZ7EElAEg" target="_blank" className="text-secondary">
                                <FontAwesomeIcon size="lg" icon={['fab', 'telegram']} />
                            </a>
                            <a href="https://twitter.com/watafandapp" target="_blank" className="text-secondary">
                                <FontAwesomeIcon size="lg" icon={['fab', 'twitter']} /> 
                            </a>
                            <a href="https://www.instagram.com/watafandapp/" target="_blank" className="text-secondary">
                                <FontAwesomeIcon size="lg" icon={['fab', 'instagram']} /> 
                            </a>
                            <a href="https://www.facebook.com/watafandapp/" target="_blank" className="text-secondary">
                                <FontAwesomeIcon size="lg" icon={['fab', 'facebook']} /> 
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
                        <h6 className="text-uppercase font-weight-bold mb-4">Watafan</h6>
                        <ul className="list-unstyled mb-0">
                                    <li className="mb-2"><span href="#" className="text-muted " role="button" onClick={() => { setShowLegal(true)}}>Legal</span></li>
                                    <li className="mb-2"><a href="mailto:info@watafan.com" className="text-muted">info@watafan.com</a></li>
                            </ul>
                    </div>
                </div>
                <div className = "row d-flex justify-content-center align-intems-center">
                    <span className="text-dark text-center">Construido en</span>
                    <a href="https://rsk.co/" target="_blank" style={{width: "120px"}}><img  src="https://www.rsk.co/img/rsk_logo.svg" alt="RSKLogo" /></a>
                    <a className="text-center text-secondary" href="http://explorer.rsk.co/" target="_blank" rel="noopener noreferrer">Ver explorador</a>
                </div>
            </div>

            <div className="bg-secondary text-light py-3">
                <div className="container text-center">
                    <p className="text-light mb-0 py-4">© 2021 Watafan All rights reserved.</p>
                </div>
            </div>
        </footer>  
            <Privacy show={showLegal} onHide={() => setShowLegal(false)}/>
        </>
    )
}