import React, { useEffect, useState}from 'react'
import Card from 'react-bootstrap/Card';
import ApiService from '../../services/ApiService'
import './styles.scss';

const WatacardThumb = function WatacardThumb({ watacardData }) {
    const [type,setType] = useState();

    console.log(watacardData)
    useEffect(() => {
        ApiService.getFileType(watacardData.image)
        .then((res)=>{

            setType(res.data.type)
        })
    },[])
    return (
        <Card style={{
            width: '138px', height: '217px'
        }}>
            {(type === 'image')
                ? < Card.Img variant="top" className="watacardThumb"  src={watacardData.image} />
                : <video className="watacardThumb" src={watacardData.image} autoPlay loop muted playsInline></video>

            }
            <Card.ImgOverlay className="h-100 d-flex flex-column justify-content-end">
                <Card.Text>{watacardData.title}</Card.Text>
            </Card.ImgOverlay>
        </Card>
    )
}


export default WatacardThumb
