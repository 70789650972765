import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import {useLocation} from 'react-router-dom';
import './Payment.scss'
export default function Payment() {
   
    const location = useLocation();
    const [data, setData]=useState();
    useEffect(() => {
        if (location.state.state) {
            setData(location.state.state)
        }
    }, [])
    const onIframeLoad = (e) => {
        console.log(e);
    }

    return (
        <Container fluid>
            {data
                ? <iframe className="pay-frame" src={data.trustee_shortlink.short_url} frameborder="0" onLoad={onIframeLoad}></iframe>
            
            : <span>Cargando... </span>
            }
        </Container>
    )
    
}
