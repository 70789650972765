import React, { memo, useState, useEffect } from 'react';
import HomeCarousel from '../../components/HomeCarousel/HomeCarousel'
import Feed from '../../components/Feed/Feed';
import WatacardsList from '../../components/WatacardsList/WatacardsList'
import ApiService from '../../services/ApiService';
import { Row } from 'react-bootstrap';
import WatacardLoading from '../../components/WatacardLoading/WatacardLoading';


const Home = memo(function Home(props) {
    const [feedData, setFeedData] = useState([])
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        ApiService.getHomeData()
            .then(feedResp => {
                const arrOfData = Object.values(feedResp.data).filter(el => !Array.isArray(el))
                setFeedData([... arrOfData])
            })
    }
    

    return (
        <div className="col-12">
            <HomeCarousel />
            <Feed>
                {feedData.length? feedData.map(list =>{
                    return(
                        <WatacardsList data={list}/>
                    )
                })
            :
            <Row className="px-lg-3" style={{minHeight:"550px"}}>
                {window.screen.width > 765? 
                <Row className="d-flex flex-row">
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                    <WatacardLoading/>
                </Row>
                :
                <div>
                    <WatacardLoading/>
                    <WatacardLoading/>
                </div>
            }
                
            </Row>
            }
            </Feed>
        </div>
    )
})


export default Home
