import Reac, {useState, useEffect} from 'react';
import {default as BootstrapContainer} from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../common/Button/Button'
import Row from 'react-bootstrap/Row'
import { Link, useHistory } from 'react-router-dom'
import { useWallet } from '../../context/WalletContext';


import './Container.scss'
export default function Container(props) {
    const children = props.children;
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    const context = useWallet();

    const handleRefresh = () => {
        setLoading(true);
        props.onHandleRefresh()
        .then(() =>{
            setLoading(false);
        })
    }

    useEffect(() => {
        if (context.state.provider && (context.state.noUser || JSON.stringify(context.state.user) === '{}')) {
            history.push('/profile/new')
        }
    }, [context])

    return (
        <BootstrapContainer {...props}>
            <Row className='m-3'>
                <div className="text-center position-relative">
                    <h4 className="d-inline container-title">{props.title}</h4>
                    {props.subtitle && <span style={{display: 'block', fontSize:'12px'}}>{props.subtitle}</span>}
                    {props.onHandleRefresh && <Button className="d-inline position-absolute right-0" onClick={handleRefresh}> <FontAwesomeIcon icon={['fas', 'sync-alt']} spin={loading} /> </Button> }
                    
                </div>
                
            </Row>
            {children}
        </BootstrapContainer>
    )
}
