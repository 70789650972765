import React from 'react'
import Container from '../../common/Container/Container'
import WatacardLoading from '../WatacardLoading/WatacardLoading'
import { Row} from 'react-bootstrap'


export default function HomeLoader() {
    return (
            <Row className="px-lg-3 d-flex flex-row" style={{minHeight:"550px"}}>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
                <WatacardLoading/>
            </Row>
    )
}
