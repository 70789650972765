import React, { useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card';
import {useLocation} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiService from '../../services/ApiService'
import ReactCardFlip from 'react-card-flip';
import './FlippingCard.scss'
export default function FlippingCard({data}) {
    const [flipped, setFlipped] = useState(false);
    const watacardImg = data.image || data.photoUrl;
    const [type, setType] = useState();

    const handleClick = (e) => {
        e.preventDefault();
        setFlipped(!flipped);
    }
    useEffect(() => {
        ApiService.getFileType(watacardImg)
            .then((res) => {
                setType(res.data.type)
            })
    }, [])
    const location =useLocation();

    return (
        <div className="d-flex flex-column">
            
            <ReactCardFlip isFlipped={flipped} containerStyle={{ width: 'fit-content' }}>
                {/* Frente de la carta */}
                <Card onClick={handleClick} className="bg-light border-secondary border-2 border rounded d-flex flex-column p-1 mx-auto my-4">
                    <div className="p-0 m-0" style={{backgroundColor:"light", height:"500px", width:"330px", textAlign:"center", verticalAlign: "middle"}}>
                        <span style={{height: "100%", display:"inline-block", verticalAlign:"middle"}}></span>
                        
                        {(type === 'image'  )
                            ? <img className="m-0" src={data.image || data.photoUrl} alt="" style={{ maxWidth: "100%", maxHeight: "100%", display: flipped? 'none' : 'inline' }} />
                            : <video src={data.image || data.photoUrl} autoPlay loop muted playsInline type='video/mp4' style={{ maxWidth: "100%", maxHeight: "100%", display: flipped ? 'none' : 'inline' }} > </video>
                        }
                    </div>
                    {/* <div className="container p-1">
                        <h6 className="text-light my-2" style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{data.title}</h6>
                    </div> */}
                </Card>

                {/* Dorso de la carta */}
                <Card style={{
                    width: '330px', height:'500px'
                }} onClick={handleClick} className="bg-secondary border rounded d-flex flex-column p-1 mx-auto my-4">
                    {location.pathname.substring(0,8) !== "/raffles"?
                    <Row className="text-light" style={{fontFamily:"Varela"}}>
                    <Col className="col-12">
                        <span className=" d-block fs-2">{data.title}</span>
                        <span className="d-block"><FontAwesomeIcon className="text-warning" icon={["fas", "check-circle"]}/> Verified </span>
                        <div style={{width: "100%"}} className="my-1 border border-light bg-light"></div>
                        <p className="fs-5 text-center">{data.description}</p>
                        <h6 >RSK address of the issuer:</h6><span style={{fontSize:"12px"}}>{data.issuer}</span>
                    </Col>
                    <Col>
                        <h6 style={{fontSize:"15px"}} className="mb-0">Asset ID: </h6><span style={{fontSize:"13px"}}>{data.assetId}</span>
                        <h6 style={{fontSize:"15px"}} className="mb-0">Smart Contract: </h6><span style={{fontSize:"13px"}}>{data.mint}</span>
                    </Col>
                    <Col>
                        <h6 style={{fontSize:"15px"}} className="mb-0">Date of issue: </h6><span style={{fontSize:"13px"}}>{data.dateForString}</span>
                        <h6 style={{fontSize:"15px"}} className="mb-0">Print run: </h6><span style={{fontSize:"13px"}}>{data.length}</span>
                    </Col>
                </Row>
                :<Row className="text-light" style={{fontFamily:"Varela"}}>
                        <Col className="col-12">
                            <span className=" d-block fs-2">{data.title}</span>
                            <span className="d-block"><FontAwesomeIcon className="text-warning" icon={["fas", "check-circle"]}/> Verified </span>
                            <div style={{width: "100%"}} className="my-1 border border-light bg-light"></div>
                        </Col>
                        <Col className="col-12">
                            <div>
                                <img style={{width:"315px"}} src="/assets/logo-mobile.png" alt="logo-watafan" />
                            </div>
                        </Col>
                    </Row>
                }
                    <Card.Img variant="top" src={data.image || data.photoUrl}  style={{opacity: 0}}/>
                </Card>
            </ReactCardFlip>
            <span className="text-center">Pulsa una vez para voltear</span>
        </div>
    )
}
