import React from 'react'
import Skeleton from 'react-loading-skeleton'


export default function WatacardLoading() {
    return (
        <div className=" border rounded d-flex flex-column p-1 mx-auto my-4" style={{width: "215px"}}>
            <div className="p-0 m-0" style={{ height:"270px", width:"200px", textAlign:"center", verticalAlign: "middle"}}>
                <Skeleton duration={2} height={250} width={190}/>
            </div>
            <div className="container p-1">
                <span className="my-2 d-block"><Skeleton duration={2}/></span>
                <div className="my-2 d-block"><Skeleton duration={2}/></div>
            </div>
        </div>
    )
}
