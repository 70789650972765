import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from '../../common/Button/Button'
import { getBitcoinValue } from '../../utils/misc';
import ContractService from '../../services/contractService'
import { useSuccess, useError } from '../../hooks/Notification';
import SessionStorageService from '../../services/SessionStorageService';
import SyncService from '../../services/SyncService';
import { priceParser } from '../../utils/watacard';
import Currency from 'react-currency-icons';
import './PriceModal.scss';
export default function PriceModal(props) {
    const [btcValue, setBtcValue] = useState(0);
    const [priceRbtc, setPriceRbtc] = useState(props.value ? priceParser(props.value) : 0);
    const [premium]= useState(props.data.premium);
    const [price, setPrice] = useState(0);
    const success = useSuccess();
    const error = useError();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBitcoinValue().then((res) => {
            setBtcValue(res.data.amount)
            if (priceRbtc) {
                setPrice((parseFloat(priceRbtc) * parseFloat(res.data.amount)).toFixed(2));
            }
        })
    }, [])
    const onPriceRbtcChange = (e) => {
        setPriceRbtc(e.target.value);
        setPrice((parseFloat(e.target.value) * parseFloat(btcValue)).toFixed(2));
    }
    const onSubmit = async (e) => {
        try {
            props.onLoading();
            props.onHide();
            if (props.value) {
                const nonce = await ContractService.getNonce(props.data.address, props.data.provider);
                SessionStorageService.setUpdateTransaction(props.data.address, nonce);
                const responseTx = await ContractService.updatePrice(priceRbtc, props.data.assetId, props.data.address, props.data.provider);

                console.log(responseTx);
                await SyncService.syncSell(props.data.address);
                success.show('Watacard actualizado  con éxito');
            }else {
                const nonce = await ContractService.getNonce(props.data.address, props.data.provider);
                SessionStorageService.setSellTransaction(props.data.address, nonce);
                const responseTx = await ContractService.putOnSale(priceRbtc, props.data.assetId, props.data.address, props.data.provider);

                console.log(responseTx);
                await SyncService.syncSell(props.data.address);
                success.show('Watacard puesto a la venta con éxito');
            }

            await props.refetch();
        } catch (e) {
            error.show(e.message);
        }finally {
            SessionStorageService.removeTransaction(props.data.address);
            SessionStorageService.removeNonce();
            props.onStopLoading();
        }

    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Precio de Venta
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Precio en RBTC</Form.Label>
                        <Form.Control type="text" placeholder="0 Rbtc" value={priceRbtc} onChange={onPriceRbtcChange} />

                    </Form.Group>
                        <br/>
                    <span>{price} </span> <div className="eur"><Currency code="EUR" size="small" className="eur" /></div>
                </Form>
                <div>Comisiones:</div>
                <div>
                    {premium ? <span>(5% Cofre Solidario, 45% Ídolo)</span>
                        : <span>(2,5% Cofre Solidario, 2,5% Ídolo)</span>}
                </div>
                <div>
                    Monto a Recibir (en RBTC): {
                        premium ? <span> {(parseFloat(priceRbtc) - (parseFloat(priceRbtc) * 0.50) )} </span>
                            : <span> {(parseFloat(priceRbtc) - (parseFloat(priceRbtc) * 0.05))} </span>
                    }
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cancelar</Button>
                <Button onClick={onSubmit}>Aceptar</Button>
            </Modal.Footer>
        </Modal>
    )
}
