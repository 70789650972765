import axios from 'axios';
const functionsV1Base = process.env.REACT_APP_FUNCTION_V1_URL || 'https://us-central1-watafan-33650.cloudfunctions.net/';

class UserService {
    static async getUserData(address) {
        return axios.get(`${functionsV1Base}getUser?address=${address}`);
    }
    static async setUserData(address, data) {

            return   axios.post(`${functionsV1Base}setUser?address=${address}`, data);

    }
    static async updateUser(address, data) {
        return axios.post(`${functionsV1Base}updateUserino?address=${address}`, data);
    }
    static async updatePhoto(address, data) {
        return axios.post(`${functionsV1Base}updatePhoto?address=${address}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }});
    }
}

export default UserService;