import { WalletProvider } from './context/WalletContext';
import Router from './common/Router/Router';
import Wallet from './common/Wallet/Wallet'
import { library } from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import './App.css';

library.add(fab, fas);

function App() {


  return (
    <WalletProvider>
      <Wallet>
        <Router/>
      </Wallet>
    </WalletProvider>


  );
}

export default App;
