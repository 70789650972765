import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../common/Button/Button';
import Form from 'react-bootstrap/Form';
import FlippingCard from '../../components/FlippingCard/FlippingCard'
import LoadingDetail from '../../components/LoadingDetail/LoadingDetail';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseValue} from '../../utils/raffle'
import { useWallet } from '../../context/WalletContext';
import Countdown from 'react-countdown'


import {useLocation, useParams, useHistory} from 'react-router-dom';
import ApiService from '../../services/ApiService';
import ContractService from '../../services/contractService';
import PaymentService from  '../../services/PaymentService';
import {useSuccess, useError} from '../../hooks/Notification';
import SessionStorageService from '../../services/SessionStorageService';
import RaffleService from '../../services/RaffleService';
import {imApplicant, getApplications} from '../../utils/raffle';
import RaffleTimer from '../../components/RaffleTimer/RaffleTimer';
import {useQuery} from '../../hooks/query';
import moment from 'moment';
export default function Raffle() {
    const [raffle, setRaffle] = useState();
    const [isFree, setIsFree] = useState();
    const [isOpen, setIsOpen] = useState();
    const [alreadyApply, setAlreadyApply] = useState(false);
    const [applications, setApplications] = useState(null);
    const [loggedIn, setLoggedIn] = useState();
    const [loading, setLoading] = useState(false);
    const query = useQuery();

    const [participations, setParticipations] = useState(1);
    const context = useWallet();
    const success = useSuccess();
    const error = useError();

    const location = useLocation();
    const history = useHistory();
    const {title} = useParams();

    useEffect(() => {
       
    }, []);

    useEffect(() => {
        if (context.state.address) {
           setLoggedIn(true);
            
            SessionStorageService.getTransaction(context.state.address)
                    .then((txdata) => {
                        console.log(txdata);
                        const tx = txdata ? txdata.type : null
                        if (tx && (tx === 'raffle' )) {
                            setLoading(true);
                            //TODO: como hacemos si recarga para el set Loading false jaja
                            const inHandler = setInterval(() => {
                                SessionStorageService.getTransaction(context.state.address)
                                    .then((checktx) => {
                                        console.log(checktx);
                                        if (!checktx) {
                                            setLoading(false);
                                            fetch()
                                            clearInterval(inHandler);


                                        }
                                    })

                            }, 3000)

                        }
                    })
            
            if (location.state && location.state.data) {
                const raffle = location.state.data;
                fetch();
                
                
            } else {
                fetch();
                //ESto es en el caso de que vengan los params de apayescrow
                const title = query.get('fannel')? query.get('fannel').split('_').join(' ').split('+').join('?') : null;
                const address = query.get('address')
                const add = query.get('add')
                const uid = query.get('uid')
                const participations = query.get('p')
                const errorTx = query.get('error')
                console.log(history);
                if (location.search) {
                    history.push(location.pathname);
                }
                if (title && add) {
                    RaffleService.setApplicant(title, address, add, uid, participations).
                        then((res) => {
                            fetch();
                            success.show('Aplicado al Sorteo exitosamente!')

                            SessionStorageService.removeNonce();

                        })

                }
                if (errorTx) {
                    error.show('No se pudo realizar el pago')
                }

                
            }
         
        }else{
            fetch();
        }
        
    }, [context])
    useEffect(() => {

        if (location.state && location.state.apply) {
            if (loggedIn && isFree && !alreadyApply) {
                onParticipateFree();
            } if (loggedIn && isFree === false && alreadyApply === false) {
                onParticipateRBTC();
            }
        }
    }, [isFree, alreadyApply ])
    const fetch = () =>{
        ApiService.getOpenRaffles()
            .then((resp) => {
                const raffles = resp.data;
                const raffle = raffles.find((raffle) => raffle.title === title.split('_').join(' ').split('+').join('?'));
                setRaffle(raffle);
                setIsFree(raffle.price === '0');
                setIsOpen(raffle.now && moment().isBefore(moment(raffle.periode)));
                setAlreadyApply(imApplicant(raffle, context.state.address));
                setApplications(getApplications(raffle, context.state.address))
            
                
            })
    }
    const onParticipateRBTC = async ()=> {
        setLoading(true);
        try {
            const nonce =  await ContractService.getNonce(context.state.address, context.state.provider);
            SessionStorageService.setRaffleTransaction(context.state.address, nonce, RaffleService.getApplicantData(raffle.title, context.state.address, raffle.address, context.state.user.uid, participations));

            const re = await ContractService.payRaffle(context.state.address, '0x7A856196100309143e1304C2b6F11363c6bFE9B9', raffle.priceBtc, participations, context.state.provider);
            await RaffleService.setApplicant(raffle.title, context.state.address, raffle.address, context.state.user.uid, participations);
            fetch();
            success.show('Aplicado al Sorteo exitosamente!')
        } catch (e) {
            console.log(e);
            error.show(e.message);
        }finally {
            SessionStorageService.removeTransaction(context.state.address);
            SessionStorageService.removeNonce();
            setLoading(false);
        }
    }
    const onParticipateCard = async ()=> {
        try {
            setLoading(true);
            const amount = parseFloat(parseFloat(raffle.price) * participations )
            const data = await PaymentService.postExpress(amount, raffle.title, '0x7A856196100309143e1304C2b6F11363c6bFE9B9', context.state.user.uid, participations, context.state.address, raffle.address);
            history.push('/pay', {
                state: {
                    price: raffle.price,
                    address: context.state.address,
                    ...data
                }
            })
        } catch (e) {
            console.log(e);
            error.show(e.message);

        }finally{
            SessionStorageService.removeNonce();
            setLoading(false);
        }
        
    }
    const onParticipateFree =async ()=> {
        try {
            setLoading(true);
            await RaffleService.setApplicant(raffle.title, context.state.address,raffle.address,  context.state.user.uid, 1);

            fetch();
            success.show('Aplicado al Sorteo exitosamente!');

        } catch (e) {
            console.log(e);
            error.show(e.message);

        }finally {
            setLoading(false);
        }
    }

    const onConnectWallet = ()=> {
        context.state.connect();
    }

    const renderButtons = () => {
        if (!loggedIn) {
            return <Button loading={loading} onClick={onConnectWallet}>Conectar Monedero</Button>
        }

        if (isOpen) {
            if (isFree && !alreadyApply) {
                return <Button className="text-light" variant="info" size="lg" style={{maxWidth:"250px"}} loading={loading} onClick={ onParticipateFree}>Participar</Button>
            }else if(!isFree ) {
                return <div style={{height: "260px", maxWidth:"400px"}} className="d-flex flex-column justify-content-evenly align-items-start">
                    <Form.Label>Número de participaciones</Form.Label>
                    <Form.Control className="mt-2" as="select" value={participations} onChange={(e)=>{setParticipations(e.target.value)}}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                    <div>

                    <h5 className="mt-3">Total:</h5>
                    <h4 className="text-secondary">{parseValue(raffle.priceBtc)* participations} RBTC</h4>
                    </div>
                        {loading ?
                        <Button loading={loading}></Button>
                        : <div className="d-flex flex-row justify-content-evenly mt-5">
                            <Button size="md" variant="info" className="mx-2 text-light" onClick={onParticipateRBTC}>Pagar con RBTC</Button>
                            <Button size="md" variant="info" className="mx-2 text-light" onClick={onParticipateCard}>Pagar con Tarjeta</Button>
                        </div>
                        }
                    
                </div>


            }else{
                return <Button size="lg" className="text-light" variant="success" disabled style={{maxWidth:"200px"}}>Participando <FontAwesomeIcon icon={["fas", "check"]}/></Button>
            }
           
            
        }else{

            <span> Programado</span>
        }

    }
    return (
        <React.Fragment>
            <Link className="text-secondary" to="/raffles">
                <span className="m-1 fs-6 "><FontAwesomeIcon icon={["fas", "arrow-left"]}/> Volver a sorteos</span>
            </Link>
            <Container className="px-lg-5 py-4" style={{ minHeight: "100vh" }}>
                {raffle
                    ? <Row>
                        <Col className="d-flex flex-col justify-content-center">
                            <FlippingCard data={raffle}/>
                        </Col>
                        <Col style={window.screen.width > 990? {height: "390px"}:{height:"fit-content"}} className="my-4 d-flex flex-column justify-content-between">
                            <div>
                                <h3 className="text-secondary">{raffle.title}</h3>
                                <h5 className=" mt-3">Watacards en sorteo: {raffle.units}</h5>
                                <h5 className=" mt-3">Finaliza en <Countdown date={raffle.periode} /></h5>

                                {!isFree ? <div> <h5 className=" mt-4">Precio/Participacion:</h5><h5 className="text-secondary mt-2"> {parseValue(raffle.priceBtc)} RBTC</h5></div>: <span className="text-success fs-4 fw-bold">GRATIS</span>}
                                
                                
                            </div>
                            <div  className="bottom d-flex flex-column justify-content-around">
                                {
                                    alreadyApply && <span className="text-success d-block">Participando <FontAwesomeIcon icon={['fas', 'check']} /> ({applications})</span>
                                }
                                {renderButtons()}
                                <p style={{ fontSize: "12px" }} className="text-dark m-5">Los procesos de compra pueden tardar varios minutos. Mientras tanto puedes seguir navegando por otras pantallas pero NO CIERRES LA WEB ANTES DE QUE EL PROCESO SE HAYA COMPLETADO.</p>
                            </div>
                        </Col>
                    </Row>
                    :
                    <LoadingDetail/>
                }
            </Container>
        </React.Fragment>
    )
}
