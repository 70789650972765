import {lowercaseAddress} from './misc'
/*
    mapeo de status del watacard
    {
        0: normal, 1: en venta
    }
*/
const WATACARD_ON_SALE = 1
const WEI_BASE_SELL = 1000000000000000000;
export const watacardIsMine = (address, watacardOwner) => {
    return lowercaseAddress(address) === lowercaseAddress(watacardOwner);
}

export const watacardIsOnSale = (watacardStatus) => {
    return watacardStatus === WATACARD_ON_SALE;
}

export const priceParser = (price) => {
    return price > 1000 ? (price * 1000000/ WEI_BASE_SELL).toFixed(16).replace(/\.?0+$/, "") : price
}