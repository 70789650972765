import React, {useState} from 'react'
import Carousel from 'react-multi-carousel';
import MainSlide from '../../components/MainSlide/MainSlide'
import SecondSlide from '../../components/SecondSlide/SecondSlide'
import ThirdSlide from '../../components/ThirdSlide/ThirdSlide'
import 'react-multi-carousel/lib/styles.css';

export default function HomeCarousel() {
    const responsive = {
        superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
        },
        desktop: {
        breakpoint: { max: 3000, min: 1370 },
        items: 1
        },
        smallDesktop:{
        breakpoint: {max: 1370, min: 1200},
        items: 1
        },
        tablet: {
        breakpoint: { max: 1200, min: 765 },
        items: 1
        },
        mobile: {
        breakpoint: { max: 765, min: 420 },
        items: 1
        },
        mini: {
            breakpoint: {max: 420, min: 0},
            items: 1
        }
    };
    return (
        <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType= {[ "mini"]}
        keyBoardControl={true}
        showDots={true}
        >
            <MainSlide/>
            <SecondSlide/>
            <ThirdSlide/>
        </Carousel>
    )
}
