import React, {useState, useEffect} from 'react'
import { useWallet } from '../../context/WalletContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import RandomAvatar from '../Avatar/RandomAvatar';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'react-qr-code';
import Button from '../../common/Button/Button';


export default function MobileNav() {
    const [page, setPage] = useState("home")
    const context = useWallet()
    const provider = context.state.provider
    const [baul, setBaul] = useState();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {

   
        if (context.state.baul) {
            setBaul(context.state.baul);
        }
    }, [context])
    return (
        <div style={{height: "60px", fontSize:"30px"}} className="position-fixed fixed-bottom bg-secondary text-light d-flex flex-row justify-content-around align-items-center">
            <Link to='/' style={{margin:"0px auto"}} className="nav-link text-light  d-sm-block d-md-none"><FontAwesomeIcon icon={['fas', 'home']}/></Link>
            <Link to='/marketplace' style={{margin:"0px auto"}} className="nav-link text-light  d-sm-block d-md-none"><FontAwesomeIcon icon={['fas', 'store']}/></Link>
            <Link to='/raffles' style={{margin:"0px auto"}} className="nav-link text-light  d-sm-block d-md-none"><FontAwesomeIcon icon={['fas', 'ticket-alt']}/></Link>
            {context.state.address && <Link to='/portfolio' style={{margin:"0px auto"}} className="nav-link text-light text-left"><FontAwesomeIcon icon={['fas', 'suitcase']}/></Link>}
            
            <div>{provider && <Link to='/profile' style={{margin:"0px auto", padding: "8px 16px"}} className="d-sm-block d-md-none"><RandomAvatar user={context.state.user} /></Link>}</div>

            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cofre Solidario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <div className="w-75 d-flex flex-row align-items-center justify-content-between ">
                            <img style={{ width: "120px" }} src="/assets/img/cofre_solidario.png" alt="cofre_solidario" />

                            <span className="rbtc-baul">{baul} RBTC</span>
                        </div>
                        <span>Todo lo recaudado en este cofre se destina a causas solidarias.</span>
                        <strong>Tienes 3 formas diferentes de colaborar:</strong>
                        <ul>
                            <li className="mt-2 mb-2"> <strong >Compra y vende watacards en el marketplace.</strong>  <br/> En cada transaccion se destina entre un 2.5% y un 5% al Cofre Solidario.</li>
                            <li className="mb-2"> <strong>Participa en los sorteos solidarios.</strong>  <br /> Al menos un 10% de lo recaudado en los sorteos se destina al Cofre Solidario..</li>
                            <li className="mb-2"> <strong>Dona RBTC.</strong>  Puedes hacer transferencias directas al Cofre Solidario. Muchas Gracias 😊</li>
                        </ul>
                        <QRCode className="m-2" value={"0x13d3188605474F57a75608396Eef16Ec03e5e05d"} size="130" />
                        <span style={{fontSize: "14px"}}>0x13d3188605474F57a75608396Eef16Ec03e5e05d</span>
                        <a target="_blank" className="text-secondary" href="https://explorer.rsk.co/address/0x13d3188605474f57a75608396eef16ec03e5e05d">Ver Explorador</a>
                        <span className="text-danger">Es una direccion RSK, solo podrás enviar RBTC.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="text-light" variant="secondary" onClick={handleClose}>
                        Entendido
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
