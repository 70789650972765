import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNotification } from '../../context/NotificationContext';
function Notification() {
    const context = useNotification();
    const [notificationData, setNotificationData] = useState({});
    useEffect(() => {
        setNotificationData(context.state)
    }, [context])

    const dispatchClose = () => {
        context.dispatch({ type: 'reset' })
    }
    return (

        notificationData.show
            ? <Modal
                
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={notificationData.show}
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {notificationData.label}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        
                    <p>
                           { notificationData.message}
                </p>
                </Modal.Body>
                <Modal.Footer>
                        <Button onClick={dispatchClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>


            : null



    )
}
export default Notification

