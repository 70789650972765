import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button' 
import {Link} from 'react-router-dom'
export default function ThirdSlide() {
    return (
        <div style={{maxHeight: 600}}>
            <Row>
            <div style={window.screen.width >700?{width:'100vw',height:'600px', position:"absolute", zIndex:100}:{width:'100vw',height:'600px', position:"absolute", zIndex:100, marginTop:230}}>
                <Row>
                    <Col className="d-flex justify-content-center py-5 mx-auto align-items-center">
                        <div className="p-4" style={{width:"500px"}}>
                            <h1 className="text-light">CELEBRANDO MOMENTOS INOLVIDABLES</h1>
                            <span className="text-light d-block ">Te ofrecemos la mejor forma de apoyar causas solidarias.</span>
                            <span className="text-light d-block">¡Celebra los mejores momentos con tu ídolo y colabora!</span>
                            <Link to={{ pathname: `marketplace/` }}>
                                <Button size="lg" variant="info" className="my-3 text-light">Ver Market</Button>
                            </Link>
                         </div>
                    </Col>
                    {window.screen.width > 700 &&
                    <Col className="d-flex justify-content-center py-5">
                        <img src="/assets/img/app_3.png" alt="watafanapp" />
                    </Col>
                }
                </Row>
            </div>


                <div style={{height:'700px', position:"absolute", backgroundColor: "black", opacity:0.5}}></div>
                
                {window.screen.width > 767?
                <video   playsInline autoPlay muted loop style={{objectFit:"cover", height:"600px"}}>
                    <source type="video/mp4"  src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/videos_home%2F1070630149-hd.mp4?alt=media&token=a722d095-e70a-4309-9556-c02da3039e57"/>
                </video>
                :<img src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/images_2%2F1070630149-hd_Moment.jpg?alt=media&token=57530fc0-e3b3-4dd7-ae49-5f8ba69b0cf6" style={{objectFit:"cover", height:'600px'}}/>
            }
            </Row>
        </div>
    )
}
