import React, { useState, useEffect } from 'react'
import Container from '../../common/Container/Container';
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from '../../common/Button/Button';
import Privacy from '../../components/privacy/Privacy'
import UserService from '../../services/UserService';
import { useSuccess, useError } from '../../hooks/Notification';
import { useWallet } from '../../context/WalletContext';
import LocalStorageService from '../../services/LocalStorageService';
import { useHistory } from 'react-router-dom'
import './Register.scss'
export default function Register() {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const [address, setAddress] = useState();
    const wContext = useWallet();
    const success = useSuccess();
    const error = useError();

    useEffect(() => {
        if (wContext.state.address) {
            setAddress(wContext.state.address);
        }
    }, [wContext])
    const formParamsMapper = (htmlForm) => {
        const registerObject = { name: '', email: ''};
        registerObject.name = htmlForm[0].value;
        registerObject.email = htmlForm[1].value;
        return registerObject;
    }
    const onSubmitForm = async (e) => {
        setLoading(true);
        e.preventDefault();
        //TODO: Validar los campos
        const data = formParamsMapper(e.target);
        try {

            const response = await UserService.setUserData(address, data);
            const user = response.data;
            LocalStorageService.setUser(user);
            success.show(`Usuario ${user.displayName} creado Exitosamente!`)
            wContext.dispatch({ ...wContext.state, user, noUser: false, type: 'setProvider', data: wContext.state.provider });
            history.push('/')
        } catch (e) {
            console.log(e);
            if (e.isAxiosError) {
                error.show(e.response.data.message);
            }else{

                error.show(e.message);
            }
        } finally {
            setLoading(false);
        }

    }
    const getTitle = () => {
        return `No tenemos registros para el address ${wContext.state.address}`
    }
    return (
        <Container title={getTitle()} >
            <Row> 
                <h5>Regístrate</h5>
            </Row>
            <Row>
                <Form onSubmit={onSubmitForm}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nickname</Form.Label>
                        <Form.Control type="text" placeholder="Elige tu nombre de usuario" />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Escribe tu correo electrónico" />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label={<label role="button" className="terms-label text-secondary" onClick={() =>{setModalShow(true  ) }}>Aceptar Términos y condiciones</label>} />
                    </Form.Group>
                    <Button variant="primary" type="submit"  loading={loading}>
                        Guardar
                    </Button>
                </Form>
            </Row> 
            <Privacy show={modalShow} onHide={() => setModalShow(false)} />
            
        </Container>
    )
}
