import React from 'react'
import Watacard from '../Watacard/Watacard';
import RaffleCard from '../RaffleCard/RaffleCard';
import Carousel from 'react-multi-carousel';
import Button from '../../common/Button/Button';
import {Link} from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css';


export default function WatacardsList({data}) {
    
    console.log(data)

    const responsiveWatacards = {
        superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
        },
        desktop: {
        breakpoint: { max: 3000, min: 1370 },
        items: 4
        },
        smallDesktop:{
        breakpoint: {max: 1370, min: 1200},
        items: 3
        },
        tablet: {
        breakpoint: { max: 1200, min: 767 },
        items: 2
        },
        mobile: {
        breakpoint: { max: 767, min: 420 },
        items: 1
        },
        mini: {
            breakpoint: {max: 420, min: 0},
            items: 1
        }
    };
    const responsiveRaffle = {
        superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
        },
        desktop: {
        breakpoint: { max: 3000, min: 1370 },
        items: 2
        },
        smallDesktop:{
        breakpoint: {max: 1370, min: 1200},
        items: 1
        },
        tablet: {
        breakpoint: { max: 1200, min: 767 },
        items: 1
        },
        mobile: {
        breakpoint: { max: 767, min: 420 },
        items: 1
        },
        mini: {
            breakpoint: {max: 420, min: 0},
            items: 1
        }
    };
    return (
        <React.Fragment>
            <div className="text-light">
                <div className="d-flex flex-row justify-content-between p-1">
                    <h3 className="text-left my-3 mx-3 text-secondary">{data.name === 'marketplace' ? 'Últimos NFTs': data.name}</h3>
                    <Link to={`/${data.to}`}>
                        <Button size="md" variant="outline-info" className="m-3">{data.name === 'marketplace' ? 'Ver todos' : `Ir a ${data.name}`}</Button>
                    </Link>
                </div>

                <Carousel
                    swipeable={true}
                    draggable={true}
                    responsive= {data.name != "Sorteos"? responsiveWatacards : responsiveRaffle}
                    autoPlay={false}
                    transitionDuration={230}
                    containerClass="carousel-container"
                    centerMode={screen.width > 470? true : false}
                    minimumTouchDrag={5}
                    keyBoardControl={true}
                    >
                    {data.name != "Sorteos" ?
                    data.watacards.map(watacard =>{
                        return(
                            <Watacard  watacardData={watacard}/>
                        )
                    })
                    :data.watacards.map(watacard =>{
                        return(
                            //TODO: cambie un toque el cmp para agregar funcinalidad mientras se subia  
                            <RaffleCard  raffle={watacard}/>
                        )
                    })
                }    
                    
                </Carousel>

                {data.watacards.length == 0 &&
                    <div style={{height: "300px", borderStyle:"dashed"}} className="border border-secondary rounded d-flex justify-content-center align-items-center mt-2">
                        <span className="text-dark font fs-5 text-center">De momento no hay sorteos abiertos... Vuelve mas tarde 🤟</span>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
