import React from 'react'
const NotificationContext = React.createContext();
const baseState = { show: false, type: null, message: null, label: null };
function notificationReducer(state, action) {
    switch (action.type) {
        case 'setNotification': {
            const show = action.show || false;
            const type = action.type || null;
            const message = action.message || null;
            const label = action.label || null;
            return { show, type, message, label }
        }
        case 'reset': {

            return baseState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}
function NotificationProvider({ children }) {
    const [state, dispatch] = React.useReducer(notificationReducer, { ...baseState })
    const value = { state, dispatch }

    return <NotificationContext.Provider value={{ ...value }}>{children}</NotificationContext.Provider>
}
function useNotification() {
    const context = React.useContext(NotificationContext)
    if (context === undefined) {
        throw new Error('useWallet must be used within a WalletProvider')
    }
    return context
}
export { NotificationProvider, useNotification }