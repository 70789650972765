import React, { memo, useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from '../../common/Button/Button'
import ContractService from '../../services/contractService';
import PriceModal from '../../components/PriceModal/PriceModal';
import TransactionModal from '../../components/TransactionModal/TransactionModal'
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { watacardIsMine, watacardIsOnSale } from '../../utils/watacard';
import FlippingCard from '../FlippingCard/FlippingCard'
import { useError, useSuccess } from '../../hooks/Notification';
import {priceParser} from '../../utils/watacard';
import SessionStorageService from '../../services/SessionStorageService';
import SyncService from '../../services/SyncService';
export default function WatacardDetail({ data, address, provider, refetch, connect }) {
    const [modalShow, setModalShow] = useState(false);
    const [transactionModalShow, setTransactionModalShow] = useState(false);
    const [updatePrice, setUpdatePrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState();



    const error = useError();
    const success = useSuccess();

    const location = useLocation()
    const history = useHistory();

    useEffect(() => {
        

        

    },[]);
    useEffect(() => {
        setLoggedIn(address || false);
        if (location.state && location.state.apply) {
            if (address ) {
                buy();
            }
        }

        if (address) {
            SessionStorageService.getTransaction(address)
                .then((txdata) => {
                    console.log(txdata);
                    const tx = txdata?txdata.type : null
                    if (tx && (tx === 'sell' || tx === 'buy' || tx === 'cancel' || tx === 'updatePrice')) {
                        setLoading(true);
                        //TODO: como hacemos si recarga para el set Loading false jaja
                        const inHandler = setInterval(() => {
                            SessionStorageService.getTransaction(address)
                                .then((checktx) => {
                                    console.log(checktx);
                                    if (!checktx) {
                                        setLoading(false);
                                        refetch()
                                        clearInterval(inHandler);


                                    }
                                })

                        }, 2000)

                    }
                })
        }
    }, [address])
    const putOnSale = async (e) => {
        try {
            const noFunds = await ContractService.noFunds(66000000, address, provider);
            if (noFunds) {
                throw new Error('No tienes fondos suficientes');
            } else {
                setModalShow(true);

            }
           // setModalShow(true);
        } catch (e) {
            console.log(e);
            error.show(e.message)
        }
        
    }
    const changePrice = async (e) => {
        setModalShow(true);
        setUpdatePrice(true)
    }
    const cancel = async (e) => {
        try {
            setLoading(true);
            SessionStorageService.setCancelTransaction(address);
            const resp = await ContractService.cancelSell( data.assetId, address, provider);
            console.log(resp);
            await SyncService.syncSell(address);
            await refetch();
        } catch (e) {
            console.log(e);
            error.show(e.message)
        } finally {
            // importasnte
            SessionStorageService.removeTransaction(address);
            SessionStorageService.removeNonce();
            setLoading(false);
            
        }
    }
    const onConnect = () => {
        connect();

    }
    const buy = async (e) => {
        try {
            setLoading(true);
            const nonce = await ContractService.getNonce(address, provider);
            SessionStorageService.setBuyTransaction(address, nonce, data.owner.toLowerCase() );
            const noFunds = await ContractService.noFunds(parseFloat(data.next_price), address, provider);
            if (noFunds) {
                throw new Error('No tienes fondos suficientes');
            }
            const resp = await ContractService.buyWatacard(parseFloat(data.next_price),data.assetId, address, provider);
            console.log(resp);
            await SyncService.syncBuy(address, data.owner);
            await refetch();
        } catch (e) {
            console.log(e);
            error.show(e.message)
        }finally {
            // importasnte
            SessionStorageService.removeTransaction(address);
            SessionStorageService.removeNonce();
            setLoading(false);
            
        }
    }
    const renderButtons = () => {
        const isMine = watacardIsMine(address, data.owner);
        const isOnSale = watacardIsOnSale(data.status)
        if (loggedIn === false) {
           return <Button variant="info" size="md" onClick={onConnect} className="text-light fw-bold shadow-sm mx-1">Conectar Monedero</Button>
        }
        if (isMine && !isOnSale) {
            return (<Button variant="info" size="md" loading={loading} onClick={putOnSale} className="text-light fw-bold shadow-sm mx-1">Poner a la Venta</Button>)
        }
        if (isMine && isOnSale) {
            if (loading) {
                return (
                    <Button variant="info" size="md" loading={loading} className="text-light fw-bold shadow-sm mx-1"></Button>
                )
            }else{
                return (

                    <React.Fragment>
                        <Button variant="info" size="md" onClick={changePrice} className="text-light fw-bold shadow-sm mx-1">Cambiar Precio</Button>
                        <Button variant="info" size="md" onClick={cancel} className="text-light fw-bold shadow-sm mx-1">Cancelar Venta</Button>
                    </React.Fragment>
                )
            }
            
        }
        if (!isMine && isOnSale) {
            return (<Button variant="info" size="md" loading={loading}  onClick={buy} className="text-light fw-bold shadow-sm mx-1">Comprar</Button>)
        }
    }


    return (
        <React.Fragment>
            <Link className="text-secondary" to={location.pathname.substring(0, 10) =="/portfolio"? "/portfolio" : "/marketplace"}>
                <span className="m-1 fs-6 "><FontAwesomeIcon icon={["fas", "arrow-left"]}/> Volver al {location.pathname.substring(0, 10) =="/portfolio"? <span>Portfolio</span> :<span>Marketplace</span>}</span>
            </Link>
            <Container className="px-3 py-4"style={{minHeight:"100vh"}}>
                <Row>
                    <Col className="d-flex flex-col justify-content-center">
                        <FlippingCard data = {data}/>
                    </Col>
                    <Col style={window.screen.width > 990? {height: "530px"}:{height:"fit-content"}} className="my-4 d-flex flex-column justify-content-between">
                            <div>
                                <h3 className="text-secondary">{data.title}</h3>
                            </div>
                            <div className="bottom">
                                <h6 className="text-secondary">Descripcion</h6>
                                <p>{data.description}</p>
                                <h6 className="text-secondary">Owner Address</h6>
                                <p>{data.owner}</p>
                                <h6 className="text-secondary">RSK Address of the Issuer</h6>
                                <p>{data.issuer}</p>
                                {location.pathname.substring(0, 10) =="/portfolio"  && 
                                <div>
                                    <h6 className="text-secondary">Precio pagado:</h6>
                                    <h3 className="text-secondary">{priceParser(data.last_price)} RBTC</h3>
                                </div>}
                                    
                            {watacardIsOnSale(data.status) && <div><h6 className="text-secondary">Precio Actual:</h6> <h3 className="text-secondary">{priceParser(data.next_price)} RBTC</h3></div>}
                                {renderButtons()}
                            <p style={{ fontSize: "12px" }} className="text-dark">Los procesos de cambio de precio y cancelación de venta pueden tardar varios minutos. Mientras tanto puedes seguir navegando por otras pantallas pero NO CIERRES LA WEB ANTES DE QUE EL PROCESO SE HAYA COMPLETADO.</p>
                            </div>
                    </Col>
                </Row>
            </Container>
           
            <PriceModal
                refetch={refetch}
                show={modalShow}
                onHide={() => setModalShow(false)}
                onLoading={() => setLoading(true)}
                onStopLoading={() => setLoading(false)}
                value={updatePrice ? data.next_price: null }
                data={{
                    assetId: data.assetId, address, provider, premium: data.last_price === 0
                }}
            />
        </React.Fragment>


    )
}
