import React, { useEffect, useState } from 'react';
import { useWallet } from '../../context/WalletContext'
import ApiService from '../../services/ApiService';
import Watacard from '../../components/Watacard/Watacard';
import Container from '../../common/Container/Container';
import HomeLoader from '../../components/HomeLoader/HomeLoader';
import { Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import SyncService from '../../services/SyncService';


export default function Portfolio() {
    const [watacards, setWatacards] = useState([])
    const [noData, setNoData] = useState(false)
    const context = useWallet();
    useEffect(() => {
        fetchData();
    }, [context])
    const fetchData = () => {

        if (context.state.address) {
            ApiService.getPortFolio(context.state.address)
                .then(watacards => {
                    setNoData(!watacards.total)
                    setWatacards(watacards.data)
                })
        }

    }
    const handleRefresh =  () => {
        return new Promise((resolve, reject) =>{
            SyncService.syncPortfolio(context.state.address)
                .then((result) => {
                    fetchData();
                }).catch((err) => {

                }).finally(() => {
                    return resolve(true);
                })
        })
        
        
    }


    const fallBack = () => {
        const fallback = noData
        const loader = watacards.length
        if(!fallback && !loader){
            return(
                <HomeLoader/>
            ) 
        }else if(fallback){
            return(
                <Row className="px-lg-3 d-flex flex-row justify-content-center align-items-center" style={{minHeight:"550px"}}>
                <div style={{maxWidth:"400px",fontSize:'20px'}}>
                    <h1 className="text-center ">Vaya...</h1>
                    <div className="d-flex flex-row justify-content-center align-items-center m-3">
                        <img className="position-relative mx-auto" style={{width:"120px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACSElEQVR4nO3cvW4TQRSG4TMmaYkNE8e5BGgQNNSImiQNRrmUdOmgR4rEJQQoEC3B3Aeid5AXbGi9ZCnSrfjRzB7xTdj3aa05WvuVz8q2ZDMAAAAAAAAA6IPgOWx6MJtOdh6ces4szef5bHr69uErr3kDr0GHjz5Ea+y517xSNSGcHO6d7XjNcwvw45qdmNnYa17BYj0YvPAa5hJgejCbWtM89ph1FYTG9p/svXd5vp0D9GX1tHmtos4BerR62lxWUacAfVs9bR6rKDtAX1dPW9dVlB2gx6unrdMqygrQ99XT1mUVJQdg9fxa7ipKDsDq+a2sVbSReuDW7bt/eat9Sx15pYxu3vvTw/v2Jm2e21cRyEMAMQKIEUCMAGIEECOAWPLngH+laRqrFue2WlZW1+usGRubmzYcRovbEwvB9edvN8UGqBbnVi3mnWbU67VVi7kFM4vjXZ8Lc1bsClotK7dZS8dZ3ooN4KnM5XOp2ADDUXSbtXXDb5a3Yu8BcXtiwcyWq8rqdYeb8ChajBPfi3NUbIAQgsXxbrE3Ty/FrqC+IIAYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGADECiBFAjABiBBAjgBgBxAggRgAxAogRQIwAYgQQI4AYAcQIIEYAMQKIEUCMAGIEECOAGAHECCBGALGcAN/dr+I/ETL+MC8nwFnGmV5ozN6lnkkOEJrBUTD7knquB76Gi3CUeig5wPHT6x/Xob5jZi+NdWR2+Rq8Dhfh/vGzrU/qiwEAAAAAAACAMv0EUIV+3iF9d7UAAAAASUVORK5CYII="></img>
                    </div>
                    <div style={{fontFamily: 'Varela'}}>
                       <span>Parece que aun no tienes watacards... </span><span className="d-block" >Animate a obtener el primero!&#129311;</span> 
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center m-3">
                        <Link to="/marketplace">
                            <Button size = "lg"  variant="info" className="text-light my-3">Ir al marketplace</Button>
                        </Link>
                    </div>
                    
                </div>
            </Row>
            )
        }else{
            return 
        }
    }
    return (
        <Container fluid onHandleRefresh={handleRefresh} title="Mis Watacards">
            {/* <div className="d-flex flex-row">
            <div style={{width:"23px", height:"23px"}} className="d-inline text-success rounded-circle bg-light d-flex justify-content-center align-items-center"><FontAwesomeIcon size="sm" icon={["fas", "tags"]}/></div>
            </div> */}
           
            <Row className="px-lg-3" style={{minHeight:"550px"}}>
                {watacards.map((watacard, i) => {
                        return (
                            <Col md={2} sm={6} key={i}>
                                <Watacard watacardData={{ ...watacard, id: watacard.assetId}} />
                            </Col>
                        )

                    })
                }
                {fallBack()}
            </Row>
            
        </Container>

    )
}
