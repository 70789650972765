import React, { useState, useEffect } from 'react'
import { useWallet } from '../../context/WalletContext';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from '../../common/Button/Button';
import RandomAvatar from '../Avatar/RandomAvatar';
import Modal from 'react-bootstrap/Modal';
import LocalStorageService from '../../services/LocalStorageService'
import { Link, useHistory } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import QRCode from 'react-qr-code';
import {getBitcoinValue} from '../../utils/misc'
import './Header.scss'
import { parse } from '@fortawesome/fontawesome-svg-core';


export default function Header() {
    const [user, setUser] = useState({});
    const [gotoProfile, setGotoProfile] = useState(false);
    const [baul, setBaul] = useState();
    const [rbtcPrice, setRbtcPrice] = useState();
    const context = useWallet();
    let history = useHistory();
    const provider = context.state.provider;
    const connect = context.state.connect
    const disconnect = context.state.disconnect;
    
    useEffect(() => {
        
        if (context.state.user && context.state.user !== {}) {
            setUser(context.state.user)
        }
        if (context.state.provider && (context.state.noUser || JSON.stringify(context.state.user) === '{}')) {
            setUser('Invitado')
            history.push('/profile/new')
        }
        if (context.state.baul) {
            setBaul(context.state.baul);
        }
    }, [context])
    useEffect(() => {
        getBitcoinValue()
        .then((value) =>{
            console.log(value);
            setRbtcPrice(parseFloat(value.data.amount));
        })
    }, [])
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDisconect = () => {
        disconnect();
        LocalStorageService.removeUser();
        window.sessionStorage.removeItem('prov')
        location.href = '/';
    }

    return (
        <React.Fragment>
            <header className="container-fluid navbar-expand-md bg-secondary zindex-sticky sticky-top">
                <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark" className="justify-content-between">
                    <Navbar.Brand className="col-2">
                    {window.screen.width > 767?
                        <Link to='/'>
                            <ReactSVG src="/assets/logo-navbar.svg" alt="watafan" />
                        </Link>
                    :
                    <Link to='/'>
                        <img width="70px" src="/assets/logo-mobile.png" alt="watafan" />
                    </Link> 
                       
                    }
                        

                    </Navbar.Brand>
                    {window.screen.width < 767 &&
                        <div className="baul-mobile">
                            <img onClick={handleShow} className="m-1 position-relative" style={{ width: "40px", height: "40px", cursor: "pointer" }} src="/assets/img/cofre_solidario.png" alt="cofre_watafan" />
                            {//<span className="baul-text-header">{baul} RBTC</span>
                                }
                        </div>

                    }
                        <Nav className="mr-auto text-left">
                        {window.screen.width > 767 && 
                            <div className="d-flex flex-row ">
                                <div>
                                    {//<span className="baul-text-header-desk"> {baul} RBTC</span>
                                    }
                                    <img onClick={handleShow} className="m-1 position-relative" style={{ width: "30px", height: "30px", cursor: "pointer" }} src="/assets/img/cofre_solidario.png" alt="cofre_watafan" />

                                    
                                    
                                </div>

                                <Link to='/' className="nav-link text-light">Inicio</Link>
                                <Link to='/raffles' className="nav-link text-light">Sorteos</Link>
                                {/* <Link to='/' className="nav-link text-light">Idolos</Link> */}
                                <Link to='/marketplace' className="nav-link text-light">Marketplace</Link>
                                {context.state.address && <Link to='/portfolio' className="nav-link text-light text-left">Portfolio</Link>}
                            </div>
                            }
                            <Button variant={provider? "success": "info"} size="md" onClick={provider ? onDisconect : connect} className="text-light fw-bold shadow-sm mx-1">{provider ? `Desconectar` : 'Conectar Monedero'} </Button>
                            {window.screen.width > 767 && 
                            <div>{provider && <Link to='/profile'><RandomAvatar user={user} /></Link> }</div>
                            }
                        </Nav>
                </Navbar>
                
            </header>
            
            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cofre Solidario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <div className="w-75 d-flex flex-row align-items-center justify-content-between ">
                            <img style={{ width: "120px" }} src="/assets/img/cofre_solidario.png" alt="cofre_solidario" />
                            <span className="rbtc-baul">{baul} RBTC ({parseFloat(parseFloat(baul)*rbtcPrice).toFixed(2)} EUR)</span>
                            
                        </div>
                        
                        <span>Todo lo recaudado en este cofre se destina a causas solidarias.</span>
                        <strong>Tienes 3 formas diferentes de colaborar:</strong>
                        <ul>
                            <li className="mt-2 mb-2"> <strong >Compra y vende watacards en el marketplace.</strong>  <br/> En cada transaccion se destina entre un 2.5% y un 5% al Cofre Solidario.</li>
                            <li className="mb-2"> <strong>Participa en los sorteos solidarios.</strong>  <br /> Al menos un 10% de lo recaudado en los sorteos se destina al Cofre Solidario.</li>
                            <li className="mb-2"> <strong>Dona RBTC.</strong>  Puedes hacer transferencias directas al Cofre Solidario. Muchas Gracias 😊</li>
                        </ul>
                        <QRCode className="m-2" value={"0x13d3188605474F57a75608396Eef16Ec03e5e05d"} size="130" />
                        <span >0x13d3188605474F57a75608396Eef16Ec03e5e05d</span>
                        <a target="_blank" className="text-secondary" href="https://explorer.rsk.co/address/0x13d3188605474f57a75608396eef16ec03e5e05d">Ver Explorador</a>
                        <span className="text-danger">Es una direccion RSK, solo podrás enviar RBTC.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="text-light" variant="secondary" onClick={handleClose}>
                        Entendido
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
