import React from 'react';
import RaffleActions from '../../components/RaffleActions/RaffleActions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WatacardThumb from '../../components/WatacardThumb/WatacardThumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApplicants, imApplicant, getApplications } from '../../utils/raffle';
import { parseValue} from '../../utils/raffle'
import { useWallet } from '../../context/WalletContext'

export default function RaffleCard({raffle}) {
    let context = useWallet()
    let applicants = getApplicants(raffle)
    let applicant = imApplicant(raffle, context.state.address)
    let applications = getApplications(raffle, context.state.address)
    let odds = applications / applicants * 100
    
    

    function renderOdds(){
        if(odds < 100){
            return(
            <span className="text-secondary fw-bold">Odds : {odds.toFixed(1)}%</span>
            )
        }else{
            return(
                <span className="text-secondary fw-bold">Odds : 100%</span>
            )
        }
    }
    return (
        <div className="p-4 pb-1 mx-auto my-3 shadow rounded border" style={{maxWidth:"370px"}}>
            <Row>
                <Col className="col-sm-12 col-md-6 my-2">
                    <WatacardThumb watacardData={{ assetType: raffle.assetType, image: raffle.photoUrl }} />
                </Col>
                <Col className="col-sm-12 col-md-6  p-0">
                    <h5 className="text-secondary">{raffle.title}</h5>
                        <div > 
                        <span className="text-secondary d-block">¡{raffle.units} watacards en sorteo!</span>
                        <span className="d-block text-secondary"><FontAwesomeIcon icon={["fas", "users"]}/> {applicants}</span>
                        {raffle.priceBtc == 0 ?
                        <span className="text-success fs-5 fw-bold">GRATIS</span>
                            : <span className="d-block fs-3 fw-bold text-secondary">{parseValue(raffle.priceBtc)} RBTC</span>
                        }
                        {
                            applicant && <span className="text-success d-block">Participando <FontAwesomeIcon icon={['fas', 'check']}/></span>
                        }
                        {applicant && renderOdds()}
                    </div>
                </Col>
                <Col className="col-12">
                    
                    <RaffleActions raffle={raffle} applicant={applicant}/>
                </Col>
            </Row>
        </div>
    )
}
