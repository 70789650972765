import React ,{useState, useEffect}from 'react';
import Container from '../../common/Container/Container';
import { useWallet } from '../../context/WalletContext'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import UserService from '../../services/UserService';
import { useSuccess, useError } from '../../hooks/Notification';

import Button from '../../common/Button/Button'

import RandomAvatar from '../../components/Avatar/RandomAvatar'
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom'

export default function Profile() {
  const history = useHistory()

    const [user, setUser] = useState();
    const [edit, setEdit] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [file, setFile] = useState({});
    const [loading, setLoading] = useState(false);
    const success = useSuccess();
    const error = useError();

    const [editphoto, setEditPhoto] = useState();


    const context = useWallet();

    useEffect(() => {
        if (context.state.user) {
            setUser(context.state.user );
          setName(context.state.user.displayName);
          setEmail(context.state.user.email);
        }
    }, [context])


  const savePhoto =async  () => {
    try {
      setLoading(true);

      const response =await UserService.updatePhoto(user.address, file.file)
      const useras = response.data;
      success.show(`Usuario ${useras.displayName} actualizado Exitosamente!`)
      context.dispatch({ ...context.state, user: useras, noUser: false, type: 'setProvider', data: context.state.provider });
    } catch (e) {
      console.log(e);
      if (e.isAxiosError) {
        error.show(e.response.data.message);
      } else {

        error.show(e.message);
      }
    }finally {
      setLoading(false);

    }

  }

  const save = async () => {
    try {
      setLoading(true);

      const response = await UserService.updateUser(user.address, {name, email})
      const useras = response.data;
      success.show(`Usuario ${useras.displayName} actualizado Exitosamente!`)
      context.dispatch({ ...context.state, user:useras, noUser: false, type: 'setProvider', data: context.state.provider });
      setTimeout(() => {
        history.go(0)

      }, 500);
    } catch (e) {
      console.log(e);
      if (e.isAxiosError) {
        error.show(e.response.data.message);
      } else {

        error.show(e.message);
      }
    }finally {
      setLoading(false);

    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
  }
  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleImageChange = (e)=> {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile({...file,
        file: file,
        imagePreviewUrl: reader.result
      })
    }

    reader.readAsDataURL(file)
  }
    return (
        <Container>
            { user ?
                <React.Fragment>
                    <Row>
                      {editphoto?
                        <Col>
                          <img className="img-responsive rounded-circle mx-auto d-block " width="120" height="120" src={file.imagePreviewUrl} />
                        </Col>
                        
                                :
                            <RandomAvatar user={user} size={120} />
                      }
              {!editphoto && <span role="button" className="text-center pl-1  " onClick={() => { setEditPhoto(true) }} icon={["fas", "edit"]}>Editar</span>}
                        
                    </Row>
                    {editphoto &&
                    <Row>
                      <Form.Control type="file" onChange={handleImageChange}/>
                      <Col>
                        <Button variant="success" loading={loading} onClick={savePhoto}>Guardar</Button>

                      </Col>
                      <Col>
                        <Button variant="danger" onClick={() => { setEditPhoto(false) }}>Cancelar</Button>

                      </Col>
                    </Row>
                    }
                    <br/>
                    <Row>
                    <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Nickname</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {edit?
                        <Form.Control type="text" placeholder="" onChange={onNameChange} value={name} />
                        :<React.Fragment>
                          <span> </span>
                          {user.displayName}  <span role="button" className="ml-1 text-decoration-underline" onClick={() => { setEdit(true) }} icon={["fas", "edit"]} >Editar</span>
                        </React.Fragment>


                      }
                      
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Email</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {edit?
                        <Form.Control type="email" placeholder="" onChange={onEmailChange} value={email}/>
                        :
                        <React.Fragment>
                          {user.email} 
                          <span> </span> 
                          <span className="ml-1 text-decoration-underline" role="button" onClick={() => { setEdit(true) }} icon={["fas", "edit"]}>Editar</span>
                        </React.Fragment>
                      }
                   
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Dirección</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {user.address}
                    </div>
                  </div>
                 
                  
                  </div>
                </div>

                  

                    </Row>
            {edit &&
                    <Row>
                      
                        <Col>
                          <Button variant="success" loading={loading} onClick={save}>Guardar</Button>

                        </Col>
                        <Col>
                          <Button variant="danger" onClick={()=>{setEdit(false)}}>Cancelar</Button>

                        </Col>
                      
                    </Row>
            }

                    <Row >
                        <Col className="text-center">
                            <QRCode value={user.address} size="300" />
                        </Col>
                </Row>
                
                </React.Fragment>

              : 
              null // TODO: implementar skeleton loading

            }
            
        </Container>
    )
}
