import SyncService from '../services/SyncService'
export default class SessionStorageService{
    static setSellTransaction(address, nonce){
        return SyncService.setTransaction(address, 'sell', nonce)
        .then(()=>{

        })
        
    }
    static setBuyTransaction(address, nonce, seller) {
        return SyncService.setTransaction(address, 'buy', nonce, {
            seller
        })
            .then(() => {

            })

    }
    static setCancelTransaction(address) {
        return SyncService.setTransaction(address, 'cancel')
            .then(() => {

            })

    }
    static setRaffleTransaction(address, nonce, data) {
        return SyncService.setTransaction(address, 'raffle', nonce, data)
            .then(() => {

            })

    }
    static setUpdateTransaction(address, nonce) {
        return SyncService.setTransaction(address, 'updatePrice', nonce)
            .then(() => {

            })
    }
    static removeTransaction(address) {
        return SyncService.removeTransaction(address)
            .then(() => {

            })
    }
    static async getTransaction(address) {
        const d = await SyncService.getTransaction(address);
        return d.data; 
           
    }
    static setNonce(nonce) {
        return window.sessionStorage.setItem('last_nonce', nonce);
    }
    static getNonce(nonce) {
        return window.sessionStorage.getItem('last_nonce');
    }
    static removeNonce() {
        return window.sessionStorage.removeItem('last_nonce');

    }
}