import React, {useState} from 'react';
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es') 
export default function RaffleTimer({periode}) {
    //const [remain, setRemain] = useState();
    const getRemain = () => {
        return moment(periode).fromNow();
    }
    return (
        <React.Fragment>
            { getRemain() }
        
        </React.Fragment>
    )
}
