/*
    LA funcion de este hook es encapsular toda la logica de seteo de notificaciones p[ara que nosea un choclo cada vez que se quiere emitir una notificacion en el sitio
*/
import {useNotification} from '../context/NotificationContext';
export const useSuccess = () => {
    const context = useNotification();

    return { show: (message) => { context.dispatch({ type: 'setNotification', show: true, label: 'Exito', message: message })}}
}

    export const useError = () => {
    const context = useNotification();
        return { show: (message) => { context.dispatch({ type: 'setNotification', show: true, label: 'Error', message: message })}}
    
}