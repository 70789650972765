import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function LoadingRaffleCard({raffle}) {
    return (
        <Row className="px-lg-5 py-2 d-flex direction-row justify-content-around" style={{minHeight: "550px"}}>  
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
            <div className="p-4 pb-1 mx-auto my-3  border rounded" style={{maxWidth:"370px"}}>
                <Row>
                    <Col className="col-sm-12 col-md-6 my-2">
                        <Skeleton width= {138} height= {217} />
                    </Col>
                    <Col className="col-sm-12 col-md-6  p-0">
                            <h5><Skeleton/></h5>
                            <div > 
                            <span className="d-block"><Skeleton/></span>
                            <span className="text-success fs-5 fw-bold"><Skeleton/></span>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Skeleton/>
                    </Col>
                </Row>
            </div>
        </Row>
    )
}