import React, { useState, useEffect} from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import MobileNav from '../../components/MobileNav/MobileNav'
import { NotificationProvider } from '../../context/NotificationContext';
import Notification from '../../components/Notification/Notification'

export default function Layout({ children }) {

    return (

        <NotificationProvider>
            <Header />
            {children}
            <Footer />
            {window.screen.width < 767 &&
            <div><div style={{height:"50px"}}></div><MobileNav/></div>}
            <Notification />
        </NotificationProvider>

    )
}
