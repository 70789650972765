import React, { memo, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useWallet } from '../../context/WalletContext'
import ApiService from '../../services/ApiService';
import WatacardDetail from '../../components/WatacardDetail/WatacardDetail';
import LoadingDetail from '../../components/LoadingDetail/LoadingDetail';

const Watacard = memo(function Watacard({ market }) {
    const [watacard, setWatacard] = useState();
    const context = useWallet()
    const { id } = useParams();

    useEffect(() => {
        fetchData();
    }, [context]);

    const fetchData = () => {
        if (context.state.address && !market) {
            ApiService.getWatacard(context.state.address, id).then(
                (wata) => {
                    if (wata.title == 'WIS?V') {
                        wata.assetType = 'video'
                    }

                    setWatacard(wata)
                }
            )
        }
        if (market) {
            ApiService.getWatacard(null, id).then(
                (wata) => {
                    if (wata.title == 'WIS?V') {
                        wata.assetType = 'video'
                    }

                    setWatacard(wata)
                }
            )
        }

    }
    return (
        <div>
            {watacard
                ? <WatacardDetail data={watacard} address={context.state.address} provider={context.state.provider} refetch={fetchData} connect={context.state.connect}/>
                : <LoadingDetail/>
            }
        </div>
    )
})

export default Watacard
