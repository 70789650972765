
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';



export default function Privacy(props) {
    return (
        <Modal
            size="lg"
            show={props.show}
            onHide={() => props.onHide()}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Términos y condiciones
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                Términos y condiciones
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div>
                                    <h5>Condiciones Generales y Licencia de Uso de Watafan</h5>
                                    <p><b>1. Identificación de las partes</b></p>
                                    <p>
                                        1.1. Las Condiciones Generales y Licencia de Uso aquí recogidas son
                                        suscritas, de una parte por Tookens Blockchain Technology, S.L. (en
                                        adelante, el Titular), como titular de la aplicación Watafan (en adelante,
                                        la DApp o Watafan) y de otra, por el usuario, entendido como la persona
                                        física, mayor de catorce años, o jurídica que, de forma libre y
                                        voluntaria, descarga, instala o accede a Watafan, independientemente de
                                        que haga uso o no de los servicios y herramientas allí ofrecidas en cada
                                        momento.
    </p>
                                    <p>
                                        1.2. El usuario, al acceder, descargar o instalar Watafan, acepta las
                                        presentes Condiciones en lo que pueda ser aplicable a cualquier usuario de
                                        Internet que, sencillamente, descarga la DApp. Cuando, además, al
                                        registrarse las acepta, declara ser mayor de edad conforme a la
                                        legislación española y se somete sin reserva a todo lo que aquí se
                                        dispone. El Titular recomienda realizar una atenta lectura del presente
                                        documento, así como la revisión periódica de su contenido.
    </p>
                                    <p><b>2. Watafan</b></p>
                                    <p>
                                        2.1. La principal utilidad de Watafan es, mediante el empleo de tecnología
                                        blockchain, la creación dentro de la Dapp de un monedero para almacenar y
                                        gestionar tanto criptomonedas llamadas RBTC y además cromos
                                        digitales autografiados de personajes famosos(en adelante “ídolos“)
                                        llamados Watacards, y un marketplace por el que los usuarios de la
                                        aplicación (en adelante “fans”) pueden vender dichos watacards,
                                        monetizando su infuencia con RBTC.
    </p>
                                    <p>Los ídolos deben usar la aplicación “Watafan Idols”.</p>
                                    <p>
                                        2.2. Gracias a la tecnología Blockchain, los procesos de creación,
                                        distribución y compraventa de “watacards” están regulados por un smart
                                        contract que de forma segura y transparente permite automatizar los
                                        procesos sin necesidad de intermediarios, asegurando que la posesión de un
                                        watacard es exclusiva del propietario de la clave privada del monedero
                                        donde se encuentre almacenado, que no se pueda alterar y/o modificar el
                                        número de watacards una vez registrados en la cadena de bloques y,
                                        pudiéndose asimismo comprobar de forma fehaciente quién es el emisor.
    </p>
                                    <p>
                                        Los Ídolos recibirán RBTC si los fans venden sus watacards en un
                                        mercado secundario (Marketplace) que el Titular facilitará dentro de la
                                        DApp Watafan y lo hará en concepto de derechos de Autor, si bien el
                                        Titular de la plataforma, Tookens Blockchain Technology, S.L., se quedaría
                                        con un porcentaje de la transacción como comisión de operativa, todo ello
                                        queda estipulado previamente en el smart contract.
    </p>

                                    <p>
                                        La plataforma Watafan está basada en la red blockchain de Rootstock (RSK).
    </p>
                                    <p>
                                        2.3. La descarga, instalación y registro en Watafan son de carácter
                                        gratuito.
    </p>
                                    <p>
                                        2.4. En Watafan también se ofrecen medios de contacto con el Titular, a
                                        efectos de poder formular dudas, quejas o sugerencias.
    </p>
                                    <p>
                                        2.5. Watafan está diseñada para ser empleada en dispositivos con acceso a
                                        Internet. No obstante, la DApp no incluye el acceso a Internet o a las
                                        redes de telefonía móvil, por lo que el usuario deberá pagar las tarifas
                                        del proveedor de acceso a Internet u operador móvil correspondiente.
                                        Consulta antes las condiciones de acceso y tarificación de tu proveedor.
    </p>
                                    <p>
                                        2.6. Te informamos además que Watafan puede incluir publicidad propia o de
                                        terceras empresas.
    </p>
                                    <p><b>3. Información suministrada</b></p>
                                    <p>
                                        3.1. La información relativa a los watacards proporcionados por los ídolos
                                        (precio, imágenes, disponibilidad etc.) es proporcionada por los propios
                                        Ídolos. Aunque el Titular insta a éstos a comprobarla y revisarla, de
                                        conformidad con el artículo 16 de la Ley 34/2002, de 11 de julio, de
                                        Servicios de la Sociedad de la Información y de Comercio Electrónico no
                                        asume ninguna responsabilidad al respecto, por cuanto la misma no se
                                        encuentra sometida a revisión por parte del Titular.
    </p>
                                    <p>
                                        3.2. Toda la información o procesos de compra relativa a watacards, fans e
                                        ídolos que se suministre o realice a través de la aplicación Watafan, se
                                        rige por lo dispuesto en las Condiciones y Políticas de la plataforma.
    </p>
                                    <p>
                                        3.3. Si por el contrario, la información o procesos de compra relativa a
                                        watacards, fans e ídolos, se efectúa fuera de la plataforma, Watafan ni
                                        sus Titulares son responsables de toda interacción efectuada fuera de
                                        dicha plataforma. Para más información, consúltese la cláusula 9 de las
                                        presentes Condiciones.
    </p>
                                    <p><b>4. Funcionamiento de Watafan y uso de criptomonedas</b></p>
                                    <p>
                                        4.1. Una vez un Ídolo entrega un Watacard a un fan, éste podrá atesorar y
                                        coleccionar dicho watacard o podrá ponerlo a la venta en el Marketplace de
                                        Watafan DApp, estableciendo un precio en RBTC e interactuando con
                                        otro fan a través del smart contract sin la intervención del Titular en el
                                        proceso. El smart contract establece una comisión en RBTC para el
                                        vendedor que se resta del precio total obtenido por el mismo. Tan sólo en
                                        la primera venta del watacard las comisiones serán de un 45% para el Ídolo
                                        y un 5% para el titular (Watafan). En sucesivas ventas la comisión se
                                        dividirá en partes iguales(50/50) para el Titular y para el Ídolo, siendo
                                        un 95% del monto total para el fan vendedor. El ídolo en ambos casos
                                        recibirá directamente en su monedero su comisión correspondiente.
    </p>
                                    <p>
                                        4.2. Para poder poner a la venta watacards será necesario adquirir
                                        previamente Smart Bitcoins(RBTC). Smart Bitcoin es la criptomoneda en las
                                        que se basa la blockchain de RSK y son necesarios para poder pagar las
                                        comisiones correspondientes a los mineros.
    </p>
                                    <p>
                                        4.3. Los usuarios pueden comprar y vender watacards a través del smart
                                        contract de Watafan, interactuando directamente con otros usuarios (P2P) ,
                                        sin intervención de terceras partes, quedando todo ello registrado en la
                                        red blockchain y gestionado a través de las funcionalidades que
                                        proporcionan dichos smart contracts. Para toda transacción efectuada en la
                                        plataforma, son aplicables las Condiciones Generales de los Smart
                                        Contracts en Watafan.
    </p>
                                    <p>
                                        4.4. Para la compra de watacards será necesario que el Usuario tenga
                                        fondos de RBTC en su monedero personal dentro de la propia DApp
                                        Watafan que servirán para pagar el precio establecido con el vendedor más
                                        las comisiones en RBTC correspondientes a los mineros de la red blockchain
                                        de Rootstock.
    </p>
                                    <br />
                                    

                                    <div className="d-none">
                                        aca va el cuadro de comisiones
                                    </div>


                                    <br />
                                    <p>
                                        A continuación, a fin de ilustrar las comisiones percibidas, se muestra un
                                        ejemplo donde el precio pactado son 100 RBTC y el watacard no viene
                                        directamente del Ídolo sino que ya ha sido vendido previamente al menos
                                        una vez(sucesivas ventas)
    </p>
                                    <p><b>5. Pagos y modelos de Suscripción</b></p>
                                    <p>
                                        Dentro de la App es posible encontrar secciones de pago: Pagos por sorteo,
                                        por watacard dedicado y pago por acceso a watacard físicos, entre otras.
    </p>
                                    <p>
                                        De igual forma la plataforma podrá incluir modelos de suscripciones
                                        temporales con acceso a dichas funcionalidades. Estos pagos podrán
                                        realizartse a través de un TPV, gestionado por un proveedor externo,
                                        instalado en la propia plataforma. El Usuario debe proporcionar al
                                        proveedor la información de pago válida (Visa, MasterCard u otro emisor
                                        aceptado por el proveedor de pagos). Usted reconoce y acepta que Watafan
                                        no opera, posee o controla el proveedor de pagos. El uso de su tarjeta de
                                        pago se rige por su acuerdo y la política de privacidad del proveedor de
                                        pagos, no por estos Términos. Usted acepta notificar inmediatamente al
                                        proveedor de pagos sobre cualquier cambio en su dirección de facturación
                                        (u otra información) para su tarjeta de pago. No es posible la devolución
                                        del pago una vez completado.
    </p>
                                    <p>
                                        La gestión del TPV virtual y por lo tanto la responsabilidad de su buen
                                        funcionamiento recae sobre Socialpay SL con NIF B66766700 y domicilio en
                                        Cl Tellinairesnum.24 Esc.a P.3 Pta.2  08850 - (Gava) - Barcelona.
    </p>
                                    <p>
                                        Cualquier reclamación al respecto deberá hacerse a Socialpay SL, por lo
                                        que Tookens Blockchain Technology SL quedará eximido de cualquier
                                        responsabilidad en dicho asunto. Los modelos de suscripción podrán
                                        renovarse automáticamente en periodos sucesivos.
    </p>

                                    <p><b>6. Condiciones Particulares de Registro</b></p>
                                    <p><u>6.1 Identificación de las partes</u></p>
                                    <p>
                                        Las presentes Condiciones Particulares de Registro, se suscriben de un
                                        lado, por las Titulares, y por otro lado, por el Usuario, entendido como
                                        cualquier persona física mayor de edad que, accediendo a la Plataforma, ha
                                        aceptado las presentes Condiciones y ha completado todos los pasos
                                        requeridos para proceder a su registro.
    </p>
                                    <p><u>6.2 Aceptación</u></p>
                                    <p>
                                        Se entenderán aceptadas las presentes Condiciones por el Usuario
                                        Registrado al haber hecho clic en la casilla de Registro correspondiente
                                        durante dicho proceso en la Plataforma..
    </p>
                                    <p><u>6.3 Proceso de registro</u></p>
                                    <p>
                                        Para completar el registro, el Usuario debe rellenar y enviar el
                                        correspondiente formulario de registro a través de la introducción de un
                                        email válido y el establecimiento de su correspondiente contraseña, previa
                                        aceptación de la Política de Privacidad y Condiciones Particulares de
                                        Registro.
    </p>
                                    <p>
                                        El Usuario, al completar el correspondiente formulario de registro y
                                        devenir Usuario Registrado, se compromete con las obligaciones mostradas a
                                        continuación:
    </p>
                                    <p>
                                        <i>a. No registrarse aportando deliberadamente datos falsos o inexactos,
        así como suplantar la identidad de terceros.</i>
                                    </p>
                                    <p><i>b. No comercializar, vender o transferir la cuenta a terceros.</i></p>
                                    <p><i>c. Utilizar únicamente la cuenta registrada a su nombre.</i></p>
                                    <p>
                                        <i>d. No utilizar cuentas de terceros a no ser que exista un
        consentimiento expreso.</i>
                                    </p>
                                    <p>
                                        <i>e. Ser responsable de cualquier actividad que se realice desde su
        cuenta, debiendo estar actualizada en todo momento.</i>
                                    </p>
                                    <p>
                                        <i>f. Procurar mantener una adecuada confidencialidad de los datos y
                                        claves de acceso facilitadas, puesto que asume los daños y perjuicios
        que puedan suscitarse de la violación de dicha confidencialidad.</i>
                                    </p>
                                    <p>
                                        <i>g. Ser responsable de los daños que sufra o sufran terceros por un
                                        incumplimiento de las presentes Condiciones o cualesquiera otras que
        resulten aplicables.</i>
                                    </p>

                                    <p><b>7. Propiedad intelectual e industrial y Licencia de uso</b></p>
                                    <p>
                                        7.1. En virtud de la aceptación de estas Condiciones y siempre y cuando el
                                        usuario cumpla con todas sus obligaciones conforme a lo previsto en estas
                                        Condiciones o en cualesquiera otras condiciones resultaren aplicables, el
                                        Titular otorga al usuario un derecho de uso de la DApp, tal y como ésta se
                                        ofrezca en cada momento, no exclusivo, sin posibilidad de cesión o
                                        sublicencia, en todo el mundo y por todo el tiempo que la DApp permanezca
                                        instalada en el dispositivo del usuario, en cuya virtud:
    </p>
                                    <p>
                                        <i>a. El usuario queda autorizado para la descarga e instalación ilimitada
                                        de la DApp en su dispositivo móvil, si bien podrán implementarse medidas
                                        destinadas a limitar el número de conexiones simultáneas desde un solo
        lugar o dirección IP simultáneamente.</i>
                                    </p>
                                    <p>
                                        <i>b. Asimismo, el usuario queda autorizado para acceder y utilizar la
                                        DApp, siempre y cuando ésta se encuentre activa y disponible, para fines
        particulares y privados.</i>
                                    </p>
                                    <p>
                                        7.2. En ningún caso el usuario queda facultado en virtud de esta licencia
                                        de uso para fines comerciales y/o distintos de los previstos en el
                                        apartado anterior.
    </p>
                                    <p>
                                        7.3. Esta licencia de uso no supone la cesión de ningún derecho de
                                        propiedad intelectual o industrial sobre la DApp o sobre cualquier
                                        contenido presente en la misma, siendo los derechos de propiedad
                                        intelectual sobre la DApp y todos los elementos que los conforman
                                        (incluyendo la programación, el diseño, las aplicaciones, los gráficos,
                                        los códigos, texto o imágenes allí presentes, así como los contenidos
                                        publicados y la tecnología asociada a la misma) de la titularidad
                                        exclusiva del Titular o contando ésta con los derechos o autorizaciones
                                        bastantes para su explotación. En consecuencia, queda prohibida la
                                        reproducción, distribución, comunicación pública, puesta a disposición o
                                        transformación de Watafan o de cualquier elemento integrado en la DApp y/o
                                        la DApp.
    </p>
                                    <p>
                                        7.4. De igual forma, el nombre de dominio, las marcas, nombres
                                        comerciales, y en general, cualquier signo distintivo que relativo a
                                        Watafan es de la titularidad del Titular o éste cuenta con las licencias
                                        necesarias para utilizarlos.
    </p>
                                    <p>
                                        7.5. En relación a los watacards de los ídolos, Watafan no es responsable
                                        ni dueño de los derechos de propiedad intelectual de los mismos, a
                                        excepción de los watacards emitidos por los propios Titulares de la
                                        plataforma. Los ídolos, al proporcionar y emitir sus watacards deben
                                        contar con derechos o autorización bastante para ello.
    </p>
                                    <p>
                                        7.6. Si detectas alguna infracción de los derechos de propiedad
                                        intelectual y/o industrial en Watafan, te pedimos que nos lo comuniques a
                                        la mayor brevedad escribiendo a la dirección de correo electrónico
                                        [info@watafan.com].
    </p>
                                    <p><b>8. Obligaciones del usuario</b></p>
                                    <p>8.1. El usuario se compromete a:</p>
                                    <p>
                                        <i>a. Hacer un uso diligente, correcto y lícito de Watafan, respetando la
                                        legislación vigente (y de manera especial la relativa a protección de
                                        datos y propiedad intelectual e industrial), la moral y las buenas
        costumbres, así como el orden público.</i>
                                    </p>
                                    <p>
                                        <i>b. Revisar periódicamente las presentes Condiciones Generales de Uso, o
                                        cualesquiera otras condiciones resultaren aplicables, comprobando los
        cambios que, en su caso, pudieran haber sufrido.</i>
                                    </p>
                                    <p>
                                        <i>c. Controlar las notificaciones que, en su caso, le sean remitidas por
        el Titular, puesto que pueden incluir información importante.</i>
                                    </p>
                                    <p>
                                        <i>d. No utilizar Watafan con fines comerciales; por ejemplo, recabando
                                        información o contenidos para prestar otros servicios que puedan suponer
        una clara competencia para el Titular.</i>
                                    </p>
                                    <p>
                                        <i>e. No modificar o tratar de modificar Watafan en modo alguno ni
                                        realizar acciones o utilizar medios orientados a simular su apariencia o
        sus funciones.</i>
                                    </p>
                                    <p>
                                        <i>f. Abstenerse de llevar a cabo acción alguna que conlleve la
                                        introducción de virus informáticos, gusanos, troyanos o cualquier otra
                                        clase de código malicioso destinado a interrumpir, destruir o limitar
        las funcionalidades de Watafan.</i>
                                    </p>
                                    <p>
                                        <i>g. No emplear técnicas de ingeniería inversa y/o descifrar, descompilar
                                        o utilizar cualquier otro sistema destinado a conocer el código fuente
                                        de Watafan o de cualquier elemento sujeto a copyright o propiedad
        intelectual subyacente.</i>
                                    </p>
                                    <p>
                                        <i>h. No dañar, deshabilitar, sobrecargar o dificultar el servicio (o la
                                        red o redes conectadas al servicio), o interferir en el uso y disfrute
        del mismo.</i>
                                    </p>
                                    <p>
                                        <i>i. En cualquier caso, no realizar ningún tipo de actos que puedan
                                        infringir derechos o intereses del Titular o de terceros como puedan
                                        ser, a modo de ejemplo, derechos de propiedad intelectual o industrial
        (patentes, marcas, derechos de copyright, secretos comerciales...).</i>
                                    </p>
                                    <p>
                                        8.2. Respecto de su perfil en Watafan, el usuario o “fan” se compromete a:
    </p>
                                    <p>
                                        <i>a. No identificarse aportando datos falsos o inexactos de manera
        deliberada o suplantando la identidad de terceros.</i>
                                    </p>
                                    <p>
                                        <i>b. Utilizar únicamente el perfil creado a su nombre, no utilizando
        perfiles de terceros sin su previo y expreso consentimiento.</i>
                                    </p>
                                    <p>
                                        <i>c. No vender, comercializar o transferir el perfil de usuario en
        Watafan a terceros.</i>
                                    </p>
                                    <p>
                                        <i>d. Ser el único responsable de cualesquiera actividades que se realicen
                                        desde su perfil de usuario, debiendo mantenerlo actualizado en todo
        momento.</i>
                                    </p>
                                    <p>
                                        <i>e. Procurar la estricta confidencialidad de sus datos y semillas,
                                        puesto que asume los daños y perjuicios que puedan derivarse de la
        violación de dicha confidencialidad.</i>
                                    </p>
                                    <p>
                                        <i>f. Ser responsable de los daños que sufra o que sufran terceros como
                                        consecuencia del incumplimiento de estas Condiciones o de cualesquiera
                                        otras que resulten aplicables, como puedan ser las Condiciones Generales
        de Uso.</i>
                                    </p>
                                    <p>
                                        8.3. Es deber del usuario velar por un correcto uso de la aplicación, así
                                        como el mantenimiento de la seguridad y confidencialidad de sus claves
                                        públicas y privadas, con especial atención a las semillas de recuperación
                                        de la clave privada.
    </p>
                                    <p>
                                        8.4. El usuario entiende que si no vela por el buen mantenimiento de sus
                                        claves y semillas, no podrá recuperar su cuenta ni los fondos o watacards
                                        adscritos a su usuario.
    </p>
                                    <p>
                                        8.5. Todo ello sin perjuicio de lo dispuesto en las cláusulas séptima y
                                        novena.
    </p>
                                    <p><b>9. Enlaces de terceros</b></p>
                                    <p>
                                        9.1. Es posible encontrar en Watafan links o enlaces a páginas o sitios
                                        web de terceros. El Titular no asume ninguna responsabilidad sobre los
                                        mismos o sobre su contenido o correcto funcionamiento, ni sobre las
                                        consecuencias que se deriven del acceso a los mismos.
    </p>
                                    <p>
                                        9.2. Respecto de los enlaces publicados por el Titular, el usuario
                                        reconoce y acepta que tales enlaces dirigen a sitios externos y ajenos,
                                        tanto a Watafan como al Titular y que éste no aprueba ni revisa las
                                        funciones, publicidad o, en general, el contenido incluido en páginas de
                                        terceros, aún cuando sean enlazados desde Watafan; por ello, ésta no puede
                                        garantizar que en los mismos no existan amenazas informáticas, virus o
                                        malware o que alberguen contenido ilícito o inadecuado u otros enlaces
                                        que, a su vez, lleven a sitios con alguna o varias de las anteriores
                                        características.
    </p>
                                    <p><b>10. Exclusiones de responsabilidad</b></p>
                                    <p>
                                        10.1. El Titular trabaja para que los servicios y funcionalidades de
                                        Watafan se encuentren siempre disponibles. No obstante, Watafan será
                                        mostrada “tal cual”, según la disponibilidad y según las limitaciones
                                        concurrentes en cada momento.
    </p>
                                    <p>
                                        10.2. A pesar del continuo esfuerzo realizado por el Titular para proteger
                                        los sistemas y los contenidos incluidos en Watafan, a cuyo efecto emplea
                                        los estándares de seguridad habituales en Internet, no es posible ofrecer
                                        plenas garantías en relación a las intrusiones o pérdidas de información
                                        que puedan producirse. Del mismo modo que no puede garantizarse la
                                        ausencia de virus o de otros elementos dañinos en Watafan o en sitios de
                                        terceros que puedan producir alteraciones en el sistema informático, tanto
                                        software como hardware, del usuario. Por esta razón, el usuario asume y
                                        comprende que existan situaciones que puedan escapar al control del
                                        Titular.
    </p>
                                    <p>
                                        10.3. El Titular se exime de toda responsabilidad por el acceso indebido a
                                        contenidos como consecuencia del registro que por parte del usuario se
                                        haga aportando deliberadamente datos falsos o inexactos sobre su edad.
    </p>
                                    <p>
                                        10.4. El Titular declina toda responsabilidad que se derive de un mal uso
                                        de la DApp por el usuario de Watafan, así como por el incumplimiento de
                                        las obligaciones o compromisos asumidos en virtud de las presentes
                                        Condiciones Generales y Licencia de Uso o en cualesquiera otras que
                                        resultaran aplicables, en especial los derechos de propiedad intelectual
                                        de los ídolos.
    </p>
                                    <p>
                                        10.5. Con carácter general, ni el Titular ni sus colaboradores serán
                                        responsables en caso de lucro cesante o daño emergente por cualquier
                                        cuestión.
    </p>
                                    <p>
                                        10.6. Watafan no se hace responsable de los problemas que puedan derivarse
                                        del uso de la plataforma RSK, ni de los problemas derivados del código de
                                        RSK.
    </p>
                                    <p>
                                        10.7. Watafan no es responsable de las fluctuaciones en el precio de SBTC.
    </p>
                                    <p>
                                        10.8. Watafan no se hace responsable de los daños y perjuicios que puedan
                                        surgir de o en relación con su uso de la Dapp. Esto incluye, pero no se
                                        limita a, reclamaciones, retrasos, pérdida directa de ganancias, pérdida
                                        de watacards, pérdida de criptomonedas, daños causados por su dispositivo,
                                        software de computación, sistemas, programas y los datos en ellos, y
                                        pérdida de semillas relativas a la clave privada de los usuarios.
    </p>
                                    <p>
                                        Estas limitaciones de responsabilidad se aplican ya sea que la
                                        responsabilidad o las pérdidas se basen en negligencia o cualquier otra
                                        base, incluso si Watafan ha sido advertido o debería haber sabido de la
                                        posibilidad de tales pérdidas.
    </p>
                                    <p><b>11. Obtención de RBTC y Smart Bitcoins (RBTC)</b></p>
                                    <p>
                                        Podrán comprarse RBTC desde un TPV virtual instalado en la propia
                                        Dapp usando tarjeta bancaria para el pago y recebiendo la cantidad
                                        correspondiente de RBTC en el monedero del usuario.
    </p>
                                    <p>
                                        La gestión del TPV y la responsibilidad de respaldar cada RBTC con un
                                        euro (ratio 1:1) recae sobre Socialpay SL con NIF B667667700 y domicilio
                                        en Cl. Tellinairesnum 24 Esc. a P.3 Pta 2 (08850)- Gava (Barcelona).
    </p>
                                    <p>
                                        Cualquier reclamación respecto a los procesos de compraventa de RBTC
                                        deberá hacerse a Socialpay SL, por lo que Tookens Blockchain Technology SL
                                        quedará eximido de cualquier responsabilidad en dicho asunto.
    </p>
                                    <p>
                                        Por su parte, la obtención de RBTC es ajena a la plataforma Watafan y a
                                        Socialpay SL.
    </p>
                                    <p><b>12. Indemnización</b></p>
                                    <p>
                                        12.1. Si se produjeran cualquier tipo de daños, perjuicios, pérdidas o
                                        costes (incluidos los honorarios de abogados y procuradores), sufridos por
                                        el Titular como consecuencia de un incumplimiento por parte del usuario de
                                        las presentes Condiciones Generales y Licencia de Uso o cualesquiera otras
                                        aplicables, en tal caso traerá consigo la obligación de resarcir al
                                        Titular.
    </p>
                                    <p>
                                        12.2. Del mismo modo ocurrirá en caso de que, como consecuencia de los
                                        incumplimientos del usuario, se produjeran reclamaciones de terceros
                                        contra el Titular, en cuyo caso, aquél dejará indemne a éste, pudiéndosele
                                        reclamar cualquier gasto, coste, daño o perjuicio derivado de sus
                                        acciones.
    </p>
                                    <p><b>13. Duración, resolución y exclusión</b></p>
                                    <p>
                                        13.1 La duración de estas Condiciones es de carácter indefinido y se
                                        extiende por todo el tiempo que el usuario mantenga su cuenta en Watafan.
    </p>
                                    <p>
                                        No obstante, los watacards entregados y en posesión de terceros quedan
                                        registrados en la red blockchain, por lo que las presentes condiciones son
                                        de aplicación en la medida en que incurran situaciones que contravengan
                                        las mismas.
    </p>
                                    <p>
                                        13.2. En cualquier momento, el usuario podrá resolver estas Condiciones
                                        simplemente cancelando su cuenta de usuario a través de las herramientas
                                        de la DApp destinadas a tal efecto 1.
    </p>
                                    <p>
                                        13.3. Por su parte, el Titular se reserva el derecho de resolver estas
                                        Condiciones o de bloquear o eliminar la cuenta del usuario cuando el
                                        incumplimiento grave y/o reiterado de las condiciones aquí expuestas o de
                                        cualesquiera otras condiciones que le fueran de aplicación.
    </p>
                                    <p><b>14. Protección de datos</b></p>
                                    <p>
                                        14.1. La recogida y tratamiento de los datos personales del usuario
                                        recabados por el Titular a través de Watafan se rige por una Política de
                                        Privacidad específica.
    </p>
                                    <p>
                                        14.2. En cualquier momento el usuario podrá modificar sus datos de la
                                        cuenta, o cancelar la misma a través del envío de un correo electrónico a
                                        [info@watafan.com].
    </p>
                                    <p><b>15. Salvaguarda e interpretación</b></p>
                                    <p>
                                        15.1. Estas Condiciones constituyen un acuerdo único entre el usuario y el
                                        Titular.
    </p>
                                    <p>
                                        15.2. Si la Autoridad competente declara alguna disposición como ilegal,
                                        inválida o no ejecutable, supondrá que la misma deba ser interpretada de
                                        la manera más próxima a la intención original de tal disposición. No
                                        obstante, tal declaración respecto de alguna o algunas cláusulas no
                                        perjudicará la validez de las restantes.
    </p>
                                    <p>
                                        15.3. La no exigencia por parte del Titular del cumplimiento estricto de
                                        alguno de los términos de estas Condiciones no constituye ni podrá
                                        interpretarse en ningún caso como una renuncia por su parte a exigirlo en
                                        un futuro.
    </p>
                                    <p><b>16. Idioma</b></p>
                                    <p>
                                        16.1. El idioma aplicable a estas Condiciones es el español. Si se ofrece
                                        una versión en inglés es por mera cortesía, para la comodidad del usuario.
                                        Por ello el usuario acepta expresamente que las mismas se rijan por su
                                        versión en español.
    </p>
                                    <p>
                                        16.2. Si existiera alguna contradicción entre lo indicado en la versión en
                                        español de tales Condiciones y lo que se indica en la traducción, en todo
                                        caso prevalecerá la versión española.
    </p>
                                    <p><b>17. Legislación y fuero</b></p>
                                    <p>
                                        Las relaciones entre el Titular y el usuario, se regirán por la
                                        legislación española y, en caso de conflicto en la interpretación o
                                        cumplimiento de las presentes Condiciones se someterán, con renuncia
                                        expresa a cualquier otro fuero que pudiera corresponderles, salvo que por
                                        Ley se determine de forma imperativa otro fuero distinto, a los Juzgados y
                                        Tribunales de Madrid.
    </p>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                Política de Privacidad
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div>
    <h5>Política de Privacidad de Watafan</h5>
                                <p>
                                    La presente Política de Privacidad regula la recogida y tratamiento de los datos personales
                                    que sean proporcionados por los Usuarios, cuando accedan, naveguen o interactúen en la DApp de
                                    Watafan, accesible tanto en la Play Store como la App Store (en adelante, la DApp).
    </p>
                                <p>
                                    La aceptación de esta Política de Privacidad significa que el Usuario faculta a Tookens
                                    Blockchain Technology, S.L., titular de la DApp (en adelante Watafan o el Titular,
                                    indistintamente), para realizar el tratamiento de sus datos e incluirlos en un fichero de su
                                    responsabilidad, ya sea automatizado o no, para los fines estipulados a continuación:
    </p>
                                <p>
                                    <i
                                    >a. Registro: Mediante el registro en la Dapp, será necesario que el Usuario proporcione sus
                                        datos identificativos a través de Facebook y Google+ para la tramitación del registro en la
                                        DApp y permitir el acceso a las funcionalidades de tu cuenta personal. La base jurídica del
        tratamiento de tales datos se sustenta en el consentimiento del Usuario.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >b. Compra y venta: Los watacards disponibles en la DApp, pueden ser vendidos y adquiridos
                                        por los Usuarios Registrados, siendo necesario el inicio de sesión. La base jurídica del
        tratamiento de tales datos se sustenta en el contrato de compra efectuado por el Usuario.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >c. Contacto: Si un Usuario se pone en contacto a través de los medios establecidos en la
                                        DApp a cualquier efecto, los datos facilitados serán empleados únicamente para ponerse en
                                        contacto con el Usuario. La base jurídica del tratamiento de tales datos se sustenta en el
        consentimiento del Usuario.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >d. Creación de monedero de RBTC: Para poder interactuar en la plataforma es necesario
                                        el uso de RBTC y, por ende, la creación de un monedero de criptomonedas con su
                                        dirección correspondiente. Dicho monedero se generará automáticamente al descargar la DApp .
                                        La dirección que identifica a cada usuario, queda vinculada a la cuenta del usuario en la
                                        DApp, pero ningún dato de carácter personal es cedido a la red blockchain o a terceros. La
                                        base jurídica del tratamiento de tales datos se sustenta en el consentimiento del
        Usuario.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >El periodo de conservación de estos datos relacionados con esta finalidad quedará vinculado
                                        por el tiempo que sea Usuario de Watafan, así como el tiempo máximo establecido por la Ley
        para preservar datos de relaciones mercantiles.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >e. Publicidad: Al aceptar esta Política de Privacidad, el Usuario otorga su consentimiento
                                        para el envío de publicidad de los productos de ofertados en Watafan, promociones
                                        especiales, novedades y/o descuentos entre otros, salvo se hubiera opuesto expresamente a
                                        ello en el momento de facilitar sus datos. La base jurídica del tratamiento de tales datos
        se sustenta en el consentimiento del Usuario.</i
                                    >
                                </p>
                                <p>
                                    <i
                                    >f. Cookies: la DApp dispone de tecnología para la implantación de archivos denominados
                                        cookies en el equipo que sea utilizado para el acceso y navegación por la DApp. Las cookies
                                        pueden ser bloqueadas o deshabilitadas en cualquier momento a través de las opciones de
                                        configuración del navegador. Si desea más información puede consultar nuestra política de
                                        Cookies. La base jurídica del tratamiento de tales datos se sustenta en el consentimiento
        del Usuario. http://watafan.com/politica-de-cookies</i
                                    >
                                </p>
                                <p>
                                    Todos los campos marcados con asterisco son obligatorios y su omisión impedirá que Watafan
                                    pueda gestionar el tratamiento de los datos.
    </p>
                                <p>
                                    En cualquier momento, el Usuario podrá presentar una reclamación ante la autoridad de control.
    </p>
                                <p>
                                    En cualquier momento, el Usuario puede revocar el consentimiento para el tratamiento, haciendo
                                    ejercicio de los derechos de acceso, rectificación, supresión, portabilidad, oposición y
                                    limitación al tratamiento y poniéndolo en conocimiento del Titular a través de un correo
                                    electrónico dirigido a info@watafan.com o indicándolo a la siguiente direcció n: Avd Andraitx
                                    11, 28290, Las Rozas de Madrid, Madrid, a la atención del Titular, señalando como asunto
                                    “Protección de Datos”. En ambos casos, la comunicación deberá ir acompañada de cualquier
                                    documento oficial que permita la identificación, para evitar el acceso de sus datos por parte
                                    de terceros.
    </p>
                                <p>
                                    Por ultimo, en cualquier momento a través de las herramientas habilitadas en tu cuenta
                                    personal, puedes modificar tus datos o cancelar la cuenta. También el Usuario podrá comunicar
                                    cualquier modificación por escrito o solicitar la baja, indicándolo en cualquiera de las
                                    direcciones facilitadas.
    </p>
  </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                Términos y Condiciones de Smart Contract
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div>
                                    <h5>Términos y Condiciones </h5>
                                    <p><b>1. ¿Qué es un smart contract?</b></p>
                                    <p>
                                        Un contrato inteligente (o smart contract) consiste en un conjunto de
                                        líneas de código informático, a través de las cuales se formulan
                                        relaciones condicionales entre eventos y resultados que, básicamente,
                                        permiten a esta clase de contratos resolverse de forma automática sin que
                                        sea necesaria ningún tipo de intervención posterior a la creación del
                                        contrato. Por ejemplo, en el caso de Watafan, se establecen contratos
                                        inteligentes en el momento en el que un Usuario decida comprar o vender
                                        watacards, tanto fuera como dentro de la plataforma.
    </p>
                                    <p>
                                        Las plataformas de Watafan y Wataidol, así como su sistema de smart
                                        contracts está basada en la red blockchain de Rootstock(RSK).
    </p>
                                    <p><b>2. Identificación de las Partes</b></p>
                                    <p>Las partes intervinientes en la contratación son:</p>
                                    <p>
                                        Tookens Blockchain Technology, S.L., titular de Watafan y Wataidol (en
                                        adelante, Watafan, Tookens Blockchain Technology o el Titular), con NIF
                                        B87711727 y domicilio en Avd. Andraitx 11. 28290, Las Rozas de Madrid,
                                        Madrid, titular de la DApp Watafan y Wataidol (en adelante, 'Watafan',
                                        'Wataidol' o 'la DApp', indistintamente).
    </p>
                                    <p>
                                        “Ídolo”, será aquella persona física o jurídica que cree activos
                                        (denominados “Watacards”) en la aplicación Wataidol y ponga a disposición
                                        los mismos en la plataforma Watafan.
    </p>
                                    <p>
                                        “Fan” o “Usuario”, entendido como toda persona física o jurídica que se
                                        encuentre registrado como Usuario en Watafan y complete todos los pasos
                                        orientados al uso de los servicios, de forma libre y voluntaria, incluida
                                        la aceptación del presente Términos y Condiciones de Smart Contracts en
                                        Watafan y Wataidol, y cuyo objetivo consista en adquirir y/o comprar y
                                        vender watacards.
    </p>
                                    <p>
                                        Watafan pone a disposición la dirección de correo info@watafan.com para
                                        posibles reclamaciones.
    </p>
                                    <p>
                                        <b
                                        >3. Objeto de los presentes Términos y Condiciones de Smart Contracts en
        Watafan y Wataidol</b
                                        >
                                    </p>
                                    <p>
                                        Los presentes Términos y Condiciones de Smart Contracts en Watafan y
                                        Wataidol establece un escenario y encuadre previo a todo proceso de
                                        contratación que se realice dentro o fuera de las plataformas Watafan y
                                        Wataidol. El smart contract establece las reglas básicas de uso y la
                                        interacción entre usuarios.
    </p>
                                    <p>
                                        <b
                                        >4. Condiciones aplicables a los smart contracts efectuados en
        Watafan</b
                                        >
                                    </p>
                                    <p>
                                        Las condiciones aplicables a todo smart contract e interacción efectuada
                                        en la plataforma, sin orden de preferencia, son las siguientes:
    </p>
                                    <p>
                                        1. El presente Términos y Condiciones de Smart Contracts de Watafan y
                                        Wataidol.
    </p>
                                    <p>
                                        2. Las Condiciones Generales de Uso y Licencia de Uso de la plataforma.
    </p>
                                    <p>
                                        3. Las Condiciones Particulares establecidas por los usuarios e ídolos.
    </p>
                                    <p>4. Las Condiciones de Uso de la red blockchain de Rootstock (RSK).</p>
                                    <p>
                                        <b
                                        >5. Aceptación del Términos y Condiciones de Smart Contracts en Watafan
        y Wataidol</b
                                        >
                                    </p>
                                    <p>
                                        Previamente a la formalización de la contratación, es necesario que el
                                        Usuario lea los Términos y Condiciones de Smart Contracts en Watafan y
                                        Wataidol así como cuantas otras condiciones fueran mostradas en pantalla
                                        en el momento oportuno y las acepte, expresamente, mediante un click en la
                                        casilla de verificación correspondiente.
    </p>
                                    <p>
                                        Los Términos y Condiciones de Smart Contracts en Watafan y Wataidol
                                        regulan las directrices principales por las cuales se regirá tanto la
                                        creación y la transmisión de watacards desde la DApp Wataidol, como todas
                                        las compras y ventas de watacards que se produzcan a través de la Dapp
                                        Watafan. Ello no obstante, toda contratación quedará sujeta a los términos
                                        y condiciones específicos que se establezcan para cada caso según lo
                                        especificado por cada Ídolo y/o Usuarios. Tanto el contenido de los
                                        watacards como la cantidad emitida en cada tirada depende únicamente de
                                        los Ídolos y nada tiene que ver con el smart contract. De la misma manera
                                        que los acuerdos de precio en las compraventas de watacards tan sólo
                                        depende de la voluntad de los usuarios.
    </p>
                                    <p>
                                        El Usuario aceptará y respetará dichas condiciones específicas que el
                                        oferente de watacards determine, incluyendo, con carácter enunciativo pero
                                        no limitativo, el pago de cualesquiera comisiones e importes devengados y
                                        el cumplimiento de cualesquiera normas y restricciones acerca de la
                                        disponibilidad de tarifas, productos o servicios.
    </p>
                                    <p><b>6. Arquitectura contractual de Watafan y Wataidol</b></p>
                                    <p>
                                        Cada acción efectuada por los usuarios de las distintas Dapps se graba en
                                        la cadena de bloques de RSK. Para operar (creación, transferencia, compra
                                        y venta de watacards) en las distintas Dapps se requiere RBTC(Smart
                                        Bitcoins), principal criptomoneda de la blockchain de RSK, para así poder
                                        pagar las comisiones a los mineros de la red blockchain. De este modo, se
                                        permite que las transacciones se registren con éxito en la blockchain de
                                        Rootstock. Para la recepción de watacards directamente desde los Ídolos y
                                        su posterior atesoramiento no será necesario tener fondos en el monedero.
    </p>
                                    <p>
                                        <b
                                        >Las compras y ventas de watacards se realizarán mediante una moneda
                                            digital de la propia aplicación llamada RBTC. Cada RBTC tendrá
                                            su correspondiente respaldo en euros, el intercambio se realiza a una
        tasa de 1: 1 y puede ser convertida a euros en en cualquier momento.</b
                                        >
                                    </p>
                                    <p>Establecemos 3 tipos de usuarios:</p>
                                    <p>
                                        <i
                                        >A. Ídolos: Son emisores de watacards desde la DApp Wataidol y los
                                            entregan a sus fans, los cuales recibirán dichos watacards en su
        monedero dentro de la DApp Watafan.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >B. Fan “Vendedor”: Son todos aquellos fans que reciben un watacard de
                                            un Ídolo o que han comprado uno en el Marketplace y lo tienen en su
        poder.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >C. Fan “Comprador”: Son todos aquellos fans que quieren comprar un
        watacard a un Fan Vendedor.</i
                                        >
                                    </p>
                                    <p>El proceso contractual sería el siguiente:</p>
                                    <p>
                                        <i
                                        >1. La creación de una cartera que permita gestionar tanto watacards
                                            como  RBTC dentro de las distintas Dapps es un requisito para
                                            todos los usuarios, ya sean ídolos, vendedores y compradores. Este
                                            proceso es automático al descargar la DApp. Dicha cartera debe tener
                                            (salvo en el caso de la recepción de un watacard directamente desde un
                                            Ídolo) suficientes RBTC para permitir operaciones de transmisión ya que
                                            cada una de estas transferencias lleva asociada comisiones a los mineros
        de la red.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >2. Posteriormente, el ídolo emite un nuevo activo (watacard) para un
                                            usuario (vendedor) de forma gratuita, asumiendo los costes asociados a
                                            la minería de blockchain. El vendedor, en este caso, no paga RBTC
        ni comisiones a los mineros de blockchain.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >3. Los vendedores fijan un precio en RBTC para su activo y el
                                            mismo es relanzado al mercado de forma pública y visible por cualquier
        persona con acceso a blockchain.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >4. El comprador adquiere el activo en el mercado y paga el precio
                                            acordado en RBTC. El activo pertenece al adquiriente después de
                                            haber pagado. Este proceso es ejecutado mediante un contrato inteligente
        sin la intervención de intermediarios(P2P).</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >5. Cada operación de venta en el mercado rinde una comisión en
                                            RBTC al ídolo emisor, una al titular y otra a los mineros de la red
        Blockchain, éste último en forma de RBTC.</i
                                        >
                                    </p>
                                    <p>
                                        Todo proceso contractual realizado fuera de las plataformas de Watafan y
                                        Wataidol y que no se ajuste al procedimiento previamente definido, no se
                                        encuentra sometido ni reglado por los presentes términos, eximiendo de
                                        cualquier responsabilidad al Titular de las plataformas, quedando a
                                        discreción de las partes cualquier estipulación pactada entre las mismas.
    </p>
                                    <p><b>7. Comisiones entre las partes</b></p>
                                    <p><u>7.1. Primera venta</u></p>
                                    <p>
                                        Se entiende como primera venta la primera transferencia histórica de un
                                        watacard desde su creación en el que intervienen un Fan Vendedor y uno
                                        Comprador y hay una transacción monetaria de RBTC. La única
                                        transferencia previa ha tenido que ser obligatoria y directamente desde el
                                        propio creador del watacard (el Ídolo) al Fan Vendedor.
    </p>
                                    <p>El smart contract establece dos comisiones:</p>
                                    <p>
                                        <i
                                        >1. Comisión de venta por derechos de autor: Un 45% del precio total
                                            pactado entre Fan Vendedor y Fan Comprador que irá directamente al
                                            monedero del Ídolo emisor de dicho watacard en concepto de derechos de
                                            autor. Esta cantidad se resta del precio total obtenido por el
        vendedor.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >2. Comisión de venta para la plataforma Watafan: Un 5% del precio total
                                            pagado entre Fan Vendedor y Fan Comprador en concepto de Comisión de
        Operativa.</i
                                        >
                                    </p>
                                    <p><u>7.2. Sucesivas ventas</u></p>
                                    <p>
                                        Se entiende como sucesivas ventas todos aquellas transferencias de
                                        watacards que tienen como intervinientes a un Fan Vendedor y otro
                                        Comprador, en el cual hay una transferencia de RBTC como pago de
                                        adquisición y donde el Fan Vendedor no ha obtenido el watacard
                                        directamente de un Ídolo.
    </p>
                                    <p>El smart contract establece dos comisiones:</p>
                                    <p>
                                        <i
                                        >1. Comisión de venta por derechos de autor: Un 2,5% del precio total
                                            pactado entre Fan Vendedor y Fan Comprador que irá directamente al
                                            monedero del Ídolo emisor de dicho watacard en concepto de Derechos de
                                            Autor. Esta cantidad se resta del precio total obtenido por el
        vendedor.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >2. CComisión de venta para la plataforma Watafan: Un 2,5% del precio
                                            total pagado entre Fan Vendedor y Fan Comprador en concepto de Comisión
        de Operativa.</i
                                        >
                                    </p>
                                    <br />
                                    <div className="d-none"> aca va cuadro de comisiones</div>
                                    <br />
                                    <p>
                                        A continuación, a fin de ilustrar las comisiones percibidas dentro del
                                        apartado sucesivas ventas, se muestra un ejemplo donde el precio pactado
                                        entre comprador y vendedor es de 100 RBTC.
    </p>
                                    <p>
                                        Las comisiones previamente definidas son aplicables únicamente a los
                                        procesos contractuales definidos en las plataformas de Watafan y Wataidol.
    </p>
                                    <p><b>8. Comisiones a la red blockchain de Rootstock</b></p>
                                    <p>
                                        Las comisiones de la Blockchain de RSK son variables, dependen de la
                                        saturación de la propia red (ley oferta y demanda) y de la prioridad que
                                        se quieran dar a a las transacciones. Tanto la DApp Watafan como Wataidol
                                        ajustan las comisiones automáticamente a un precio estándar velando por la
                                        buena utilización de la aplicación.
    </p>
                                    <p>Estas comisiones se pagan obligatoriamente en los siguientes casos:</p>
                                    <p><i>a. Entrega de watacards desde un Ídolo a un Fan Vendedor.</i></p>
                                    <p>
                                        <i
                                        >b. Puesta a la venta de un watacard en el Marketplace por un Fan
        Vendedor.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >c. Retirada de puesta a la venta de un watacard desde el
        Marketplace.</i
                                        >
                                    </p>
                                    <p><i>d. Compra de un watacard por parte de un Fan Comprador.</i></p>
                                    <p><b>9. Obligaciones de los Usuarios de Watafan y Wataidol</b></p>
                                    <p>
                                        Al aceptar el uso de ambas aplicaciones se acepta expresamente las
                                        condiciones establecidas en el smart contract que se ejecutarán
                                        automáticamente durante los procesos de creación, compra y venta de
                                        watacards. Independientemente a esto, los usuarios se obligan a:
    </p>
                                    <p>
                                        <i
                                        >a. Responder personalmente de la autenticidad y adecuación de los datos
        proporcionados.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >b. Asumir cualquier responsabilidad derivada del no cumplimiento de los
        requisitos exigidos para la formalización de la contratación.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >c. Respetar estas o cualesquiera otras Condiciones que resultarán
                                            aplicables en caso de que la contratación se efectúe también fuera de la
        plataforma Watafan.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >d. Cumplir con las demás obligaciones previstas en los Términos y
                                            Condiciones de Smart Contracts en Watafan y Wataidol o en cualesquiera
        otras que resultaran aplicables.</i
                                        >
                                    </p>
                                    <p>
                                        Respecto a sus obligaciones como usuario de la plataforma, el Usuario
                                        entiende que:
    </p>
                                    <p>
                                        <i
                                        >a. Debe mantener su contraseña, claves privadas y semillas seguras de
        forma segura, no pudiendo, compartirlas con nadie.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >b.El Usuario entiende que existe un mecanismo de recuperación de claves
                                            privadas llamado “semillas”, que en caso de pérdida de las mismas, será
                                            imposible recuperar o reconstruir su clave privada y por lo tanto el
        Usuario perderá el acceso a sus watacards, RBTC y RBTC.</i
                                        >
                                    </p>
                                    <p>
                                        <b
                                        >10. Exclusión de responsabilidad respecto a la contratación en Watafan
        y Wataidol</b
                                        >
                                    </p>
                                    <p>
                                        Watafan únicamente será responsable de los daños y perjuicios directos
                                        causados al Usuario como consecuencia de los contratos realizadas a través
                                        de Watafan y Wataidol cuando los mismos sean derivados de una actuación
                                        dolosa de Watafan. En particular, con carácter enunciativo pero no
                                        limitativo, Watafan no se hace responsable de:
    </p>
                                    <p>
                                        <i
                                        >a. Los errores del Contratante en la información facilitada durante la
        tramitación de la contratación.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >b. La información o los contenidos facilitados por los Usuarios e
        Ídolos.</i
                                        >
                                    </p>
                                    <p><i>c. Los watacards transmitidos.</i></p>
                                    <p>
                                        <i
                                        >d. Los daños personales y/o materiales derivados de la realización de
        cualquier proceso por medio de Watafan.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >e. Las condiciones estipuladas por las partes al margen del smart
        contract.</i
                                        >
                                    </p>
                                    <p>
                                        El Usuario reconoce y acepta que la compra y venta de watacards por medio
                                        de la llamada al smart contract se realiza bajo su propio riesgo, ventura
                                        y responsabilidad.
    </p>
                                    <p>
                                        Watafan no se hace responsable ni forma parte de ningún proceso de
                                        contratación dentro o fuera de la plataforma. Toda interacción entre
                                        usuarios se realiza “de persona a persona” y sin intermediarios.
    </p>
                                    <p>
                                        Watafan no es un asesor fiduciario. En la medida en que el usuario celebre
                                        cualquier acuerdo que utilice el Sitio, Watafan no asume ninguna
                                        obligación fiduciaria hacia el usuario.
    </p>
                                    <p>
                                        <b
                                        >11. Exclusión de responsabilidad respecto al uso de smart contracts y
        tecnología blockchain</b
                                        >
                                    </p>
                                    <p>El Usuario, entiende los siguientes aspectos:</p>
                                    <p>
                                        <i
                                        >a. Que el sistema de smart contracts a través de la plataforma ha sido,
                                            con un esfuerzo razonable, realizado por expertos técnicos con respecto
                                            a estas Condiciones y con altos estándares de seguridad, todo ello de
                                            acuerdo con el estado actual de la técnica.
      </i>
                                    </p>
                                    <p>
                                        <i
                                        >b. No obstante, el Usuario entiende y acepta que la tecnología de smart
                                            contracts se encuentra en una fase inicial de desarrollo y su aplicación
                                            de carácter experimental conlleva importantes riesgos operativos,
        tecnológicos, financieros, regulatorios y de reputación</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >c. Que, en consecuencia, el Usuario entiende que en la mayor medida
                                            permitida por cualquier ley aplicable, el Usuario no considerará a
                                            Watafan o sus desarrolladores como responsables por cualquier daño,
                                            lesión causada o relacionada con la imposibilidad de usar Watafan y/o el
        sistema de smart contracts.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >d. Que la red de mineros de la red blockchain de Rootstock son
                                            entidades externas al Titular y que éstos son los encargados de validar
                                            todas las transacciones. El buen funcionamiento del sistema depende de
        su correcta actuación, de la cual el Titular no tiene ningún control.</i
                                        >
                                    </p>
                                    <p>
                                        <i
                                        >e. Que Watafan, Wataidol ni sus Titulares son responsables de las
                                            fluctuaciones derivadas del precio de RBTC ni de los propios
        watacards.</i
                                        >
                                    </p>
                                    <p><b>12. Protección de datos</b></p>
                                    <p>
                                        Watafan cuenta con una Política de Privacidad que puedes conocer en la
                                        sección correspondiente.
    </p>
                                    <p><b>13. Indemnización</b></p>
                                    <p>
                                        Cualquier incumplimiento de este Términos y Condiciones de Smart Contracts
                                        en Watafan y Wataidol por parte del Usuario puede suponer para Watafan
                                        daños y perjuicios. Si así ocurriera, cualquier tipo de pérdidas y costes
                                        (como pueden ser los honorarios de abogados y procuradores), el Usuario
                                        estará obligado a resarcir a Watafan.
    </p>
                                    <p>
                                        Asimismo, si por los incumplimientos del Contratante se generaran
                                        cualquier tipo de reclamaciones o procedimientos contra Watafan, aquél
                                        dejará indemne a éste, pudiéndole ser reclamado cualquier gasto, coste,
                                        daño o perjuicio derivado de sus acciones.
    </p>
                                    <p>
                                        <b
                                        >14. Salvaguarda e interpretación de los Términos y Condiciones de Smart
        Contracts en Watafan y Wataidol</b
                                        >
                                    </p>
                                    <p>
                                        Las totalidad de las presentes Términos y Condiciones de Smart Contracts
                                        en Watafan y Wataidol constituyen un acuerdo único entre los Usuarios y
                                        Watafan.
    </p>
                                    <p>
                                        Cualquier referencia realizada en las presentes Condiciones a un artículo
                                        o cuerpo normativo que resulte derogado se entenderá realizada a la
                                        disposición equivalente que lo sustituya.
    </p>
                                    <p>
                                        La no exigencia del cumplimiento estricto de alguno de los términos de
                                        estas Condiciones, no supone ni puede interpretarse como una renuncia por
                                        parte de Watafan a exigir su cumplimiento en sus estrictos términos en el
                                        futuro.
    </p>
                                    <p>
                                        La declaración de nulidad de alguna o algunas de las cláusulas
                                        establecidas en las presentes Condiciones por parte de Autoridad
                                        competente no perjudicará la validez de las restantes. En este caso, las
                                        partes contratantes se comprometen a negociar una nueva cláusula en
                                        sustitución de la anulada con la mayor identidad posible con la misma. Si
                                        la sustitución deviniese imposible y la cláusula fuese esencial para las
                                        Condiciones, a juicio de la parte perjudicada por su eliminación, ésta
                                        podrá optar por la resolución del contrato.
    </p>
                                    <p><b>15. Legislación y fuero</b></p>
                                    <p>
                                        El Titular de Watafan está ubicado en Madrid. Consecuentemente, la
                                        creación, asignación de criptomonedas y el desarrollo y ejecución de los
                                        smart contracts se consideran ejecutados en España, por lo que las
                                        relaciones entre Watafan y los Usuarios se rigen por la legislación y
                                        jurisdicción españolas.
    </p>
                                    <p>
                                        Como regla general, para la resolución de conflictos relativos a los
                                        Términos y Condiciones de Smart Contracts en Watafan y Wataidol, Watafan y
                                        el Usuario que se tengan con el Titular, el fuero será, con renuncia
                                        expresa a cualquier otro, el de los Juzgados y Tribunales del Titular.
    </p>
                                    <p>
                                        En caso de conflictos relativos entre Usuarios, el fuero será el de la
                                        residencia habitual del Comprador.
    </p>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                                Sorteo
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <div>
                                    <h3>Sorteos</h3>
                                    <p>
                                        Watafan incluye una sección donde el usuario puede participar de sorteos de watacards abiertos por nuestros ídolos. Para accede a estos sorteos se necesitará estar registrado y tener una cuenta en Watafan. Estos sorteos, su periodicidad, el número de watacards en sorteo, el contenido de los watacards, etc…dependen única y exclusivamente de la estrategia de los propios ídolos por lo que Watafan no se hace responsable de tales decisiones. La responsabilidad de Watafan se limita a mantener la herramienta disponible y de su correcto funcionamiento. Asegurando además la total aleatoriedad del resultado de los sorteos.
                                    </p>
                                    <p>
                                        Estos sorteos podrán ser de pago de manera que el usuario deberá tener RBTC para poder acceder a dichos sorteos y RBTC para pagar la correspondiente comisión a los mineros de la red Blockchain.
                                    </p>

                                    <p>
                                        Proceso:
                                    </p>

                                    <p>
                                        El ídolo selecciona un periodo de tiempo en el cual el sorteo permanecerá abierto (1 hora, 6 horas, 12 horas o 24 horas). Durante este periodo los fans podrán participar en el sorteo. Una vez cerrado el periodo establecido, el ídolo procederá a la elección de los ganadores y al envío de los watacards correspondientes. Para esto el ídolo pulsará el botón “Enviar a los ganadores con un click”. Un algoritmo elegirá a los ganadores de forma completamente aleatoria y automáticamente se producirá el correspondiente envío. Los watacards no serán enviados hasta que el ídolo pulse dicho botón por lo que finalizar todo el proceso requiere de una acción por parte del ídolo. Esta acción es necesaria puesto que permite que la transacción sea firmada criptográficamente desde el monedero del ídolo. Por lo tanto la recepción por parte del fan de los watacards puede demorarse cierto tiempo.
                                    </p>
                                </div>
                            
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Modal.Body>
        </Modal>

    )
}
