import React from 'react'
import Avatar from 'boring-avatars'




export default function RandomAvatar({user, size}) {
    const renderProperAvatar = () =>{
        if(user.photoUrl){
            return(
                <img src={user.photoUrl} className="rounded-circle" style={{width:`${size||40}px`}}></img>
            )
        }else{
            return(
                <Avatar
                size={size|| 40}
                name={user.displayName}
                variant="beam"
                colors={["#7258FB", "#F45D8C", "#00B2FF", "#D61111", "#FFCE00"]}
                />
            )
        }
        
    }
    return (
        <React.Fragment>
            {renderProperAvatar()}
        </React.Fragment>
    )
}
