import axios from 'axios';
import randomstring from 'randomstring';
let fee_amount;
class PaymentService {
    
    static generateDataFannel(address, amount, fannelTitle, addressto, uid, participations,faddress) {
        //"https://app.watafan.com/",
        const baseUrl = location.origin;
        const uri = `${baseUrl}/raffles/${fannelTitle.split(' ').join('_').split('?').join('+')}?fannel=${fannelTitle.split(' ').join('_').split('?').join('+')}&add=${faddress}&uid=${uid}&transaction=${randomstring.generate(6)}&p=${participations}&address=${address}`;
        //const uriDenied = `${baseUrl}/raffles/${fannelTitle.split(' ').join('_').split('?').join('+')}?fannel=${fannelTitle.split(' ').join('_').split('?').join('+')}&add=${faddress}&uid=${uid}&transaction=${randomstring.generate()}&p=${participations}&address=${address}`;
        const uriDenied = `${baseUrl}/raffles/${fannelTitle.split(' ').join('_').split('?').join('+')}?error=true`;
        return {
            name: "Aplicacion a sorteo",
            amount: amount,
            source: "pk_production_TkdzeGp6QzM=",
            trustee_confirmed_url: uri, //"https://app.watafan.com/",
            trustee_denied_url: uriDenied,  //"https://app.watafan.com/",
            settlor_confirmed_url: uri, // "https://app.watafan.com/",    
            settlor_denied_url: uriDenied, //"https://app.watafan.com/",

            tag: address,
            fee_amount
        };
    }
    static async postExpress(amount, fannelTitle, addressto, uid, participations = 1, address, faddress) {
        
        const totalamount = PaymentService.calculateCommissions(amount);
        const $data = JSON.stringify(
            PaymentService.generateDataFannel(address, totalamount.toString(), fannelTitle, addressto, uid, participations, faddress),
            );

        const apiUrl = "https://api.truust.io/1.0/express";

        const httpOptions = {
            headers:{ "Content-Type": "application/json" }
        };

        const resp = await axios.post(apiUrl, $data, httpOptions);
        return Promise.resolve(resp.data)
    }

    static calculateCommissions(amount, marketpayCo =1, watafanCo=0.4 ) {
        let comission =''
        let amountCo=''
        let percentBuyer=''
        let totalAmount=''
        let buyamount = parseFloat(amount);

        comission = parseFloat(marketpayCo) + parseFloat(watafanCo);
        percentBuyer = 100 - comission;
        amountCo = (buyamount * (comission / 100)) / (percentBuyer / 100);
         fee_amount = amountCo.toString();
        totalAmount = buyamount + amountCo;
        return totalAmount;
    }

}

export default PaymentService;