import React, { useState, useEffect, Fragment } from 'react'
import RLogin from '@rsksmart/rlogin';
import UserService from '../../services/UserService';
import ApiService from '../../services/ApiService';

import LocalStorageService from '../../services/LocalStorageService'
import WalletConnectProvider from '@walletconnect/web3-provider';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useWallet } from '../../context/WalletContext';

let rLogin = new RLogin({
    cachedProvider: true, // :(
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    //1: 'https://mainnet.infura.io/v3/8043bb2cf99347b1bfadfb233c5325c0',
                    30: 'https://public-node.rsk.co',
                    31: 'https://public-node.testnet.rsk.co'
                }
            }
        }
    },
    supportedChains: [1, 30, 31],
})
window.rLogin = rLogin;
export default function Wallet({ children }) {
    const [provider, setProvider] = useState();
    const [account, setAccount] = useState();
    const [noRsk, setNoRsk] = useState(false);

    const { dispatch } = useWallet();

    useEffect(() => {
        if (LocalStorageService.checkProvider() && !window.sessionStorage.getItem('prov')) {
            LocalStorageService.removeLogin();

        }
        const provider = rLogin.cachedProvider;
        if (provider) {
            const p = LocalStorageService.isWalletConnect() ? 'walletconnect' : 'injected'
            rLogin.connectTo(p)
                .then((prov) => {
                    const provider = prov.provider;
                    setProvider(provider)
                   
                    provider.request({ method: 'eth_accounts' }).then((accounts) => {
                        console.log(accounts);
                        const account = accounts[0];
                        setAccount(account);
                        const user = LocalStorageService.getUser();
                        const noUser = !user
                        dispatch({ type: 'setProvider', data: provider, connect, address: account, disconnect: prov.disconnect, user, noUser });
                        ApiService.getBaulBalance()
                            .then((balance) => {
                                if (balance.data.val) {
                                    dispatch({ type: 'setProvider', data: provider, connect, address: account, disconnect: prov.disconnect, user, noUser, baul: balance.data.val });

                                } else {
                                    dispatch({ type: 'setProvider', data: provider, connect, baul: null });

                                }
                            })
                    })

                })
                .catch((error) => {
                    console.log(error)
                    LocalStorageService.removeLogin();
                    dispatch({ type: 'setProvider', connect })
                })
        } else {
            dispatch({ type: 'setProvider', connect })
            ApiService.getBaulBalance()
                .then((balance) => {
                    if (balance.data.val) {
                        dispatch({ type: 'setProvider', data: provider, connect, baul: balance.data.val });

                    } else {
                        dispatch({ type: 'setProvider', data: provider, connect,  baul: null });

                    }
                })
        }
       

    }, [])
    const connect = () =>{
        
        rLogin.connect()
            .then((rloginResponse) => { // the provider is used to operate with user's wallet
                const provider = rloginResponse.provider;
                if (provider.networkVersion == '1') {
                    rloginResponse.disconnect();
                    setNoRsk(true);
                    return
                }
                // rL
                setProvider(provider);
                rLogin.setCachedProvider('injected');
                window.sessionStorage.setItem('prov', 'injected');
                // request user's account
                provider.request({ method: 'eth_accounts' }).then(([account]) => {
                    setAccount(account);
                    UserService.getUserData(account).then((user) => {
                        if (user.data) {
                            dispatch({ type: 'setProvider', data: provider, connect, disconnect: rloginResponse.disconnect, address: account, user: user.data });
                            LocalStorageService.setUser(user.data);
                            ApiService.getBaulBalance()
                            .then((balance) => {
                                if (balance.data.val) {
                                    dispatch({ type: 'setProvider', data: provider, connect, disconnect: rloginResponse.disconnect, address: account, user: user.data, baul:balance.data.val });

                                } else {
                                    dispatch({ type: 'setProvider', data: provider, connect, disconnect: rloginResponse.disconnect, address: account, user: user.data, baul: null });

                                }
                            })
                        } else {
                            dispatch({ type: 'setProvider', data: provider, connect, disconnect: rloginResponse.disconnect, address: account, noUser: true });
                        }
                    })
                })

            })
            .catch((error) => { console.log(error) })
        const buttonEl = document.querySelector('.rlogin-modal-close-button');

        buttonEl.addEventListener('click', () => {
            rLogin.closeModalIfOpen();
        })


    }

    rLogin.on('disconnect', () => {
        console.log('here');
        dispatch({ type: 'disconnect' });

    })



    return (

        <Fragment>
            {provider && !account
                ? <h1>cargando...</h1>
                : (children)
            }
            {noRsk && <Modal
                show={noRsk}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { setNoRsk(false) }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Error en la wallet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>Estás intentando conectarte a una red no permitida, verifica que estés conectado a la red de RSK.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setNoRsk(false) }}>Aceptar</Button>
                </Modal.Footer>
            </Modal>
            }

        </Fragment>
    )
}
