import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../../common/Button/Button' 
import {Link} from 'react-router-dom'

export default function MainSlide() {
    return (
        <div style={{maxHeight: 600}}>
            <Row>
                <div style={window.screen.width >700?{width:'100vw',height:'600px', position:"absolute", zIndex:100}:{width:'100vw',height:'600px', position:"absolute", zIndex:100, marginTop:230}}>
                    <Row>
                        <Col className="d-flex justify-content-center py-5 mx-auto align-items-center">
                            <div className="p-4" style={{width:"500px"}}>
                                <h1 className="text-light">CONECTANDO FANS CON SUS IDOLOS</h1>
                                {/* <span className="text-light d-block ">A traves de NFTs con corazón.</span> */}
                                <span className="text-light d-block">Comprando y vendiendo NFTs o participando en los sorteos ayudas a llenar nuestro Cofre Solidario</span>
                                <Link to={{ pathname: `marketplace/` }}>
                                    <Button size="lg" variant="info" className="my-3 text-light">Ver Market</Button>
                                </Link>
                            </div>
                        </Col>
                        {window.screen.width > 700 &&
                        <Col className="d-flex justify-content-center py-5">
                            <img src="/assets/img/app_1.png" alt="watafanapp" />
                        </Col>
                    }
                    </Row>
                </div>
                <div style={{height:'700px', position:"absolute", backgroundColor: "black", opacity:0.5}}></div>
                {window.screen.width > 767?
                <video   playsInline autoPlay muted loop style={{objectFit:"cover", height:'600px'}}>
                    <source type="video/mp4"  src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/videos_home%2F1025781242-hd.mp4?alt=media&token=2349316c-8f68-4b9d-9f4c-b8eaeed46d2a"/>
                </video>
                :<img src="https://firebasestorage.googleapis.com/v0/b/watafan-33650.appspot.com/o/images_2%2F1025781242-hd_Moment.jpg?alt=media&token=c1c553af-cb0d-420b-9551-62d4731b5361" style={{objectFit:"cover", height:'600px'}}/>
            }
            </Row>
        </div>
    )
}
