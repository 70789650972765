class LocalStorageService {
    static getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
    static setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    static removeUser() {
        localStorage.removeItem('user');
    }
    static removeLogin(){
        localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
        localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');

        
        localStorage.removeItem('walletconnect');

        localStorage.removeItem('user');
    }
    //only for mobile
    static checkProvider() {
        return localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')?localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER').includes('injected') : false
    }
    static isWalletConnect() {
        return localStorage.getItem('walletconnect')
    }
}


export default LocalStorageService;