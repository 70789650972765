import axios from 'axios';
const BTC_URL = 'https://api.coinbase.com/v2/prices/spot?currency=EUR';
export const getBitcoinValue = async () => {
    const resp = await axios.get(BTC_URL);
    return Promise.resolve(resp.data);
}

export const lowercaseAddress = (address) => {
    if (!address) {
        return undefined;
    }
    return address.toLowerCase();
}