import Web3 from "web3";
import axios from 'axios';
import SessionStorageService from './SessionStorageService';
const functionsV1Base = process.env.REACT_APP_FUNCTION_V1_URL || 'https://us-central1-watafan-33650.cloudfunctions.net/';
const WEI_BASE = 1000000
const WEI_BASE_SELL = 1000000000000000000


class ContractService {
    //----------------------------------Actions-----------------------------------
    static async putOnSale(price, assetId, address, provider) {
        price = parseFloat(price.replace(',', '.'));
        const optionsResponse = await ContractService.getPayloadForSale(price, assetId, address);
        return ContractService.sendTransaction(price, address, provider, optionsResponse, false)
    }
    static async buyWatacard(price, assetId, address, provider) {
        price = Math.floor(price * WEI_BASE);
        const optionsResponse = await ContractService.getPayloadForBuy(price, assetId, address);
        return ContractService.sendTransaction(price, address, provider, optionsResponse)
    }
    static async payRaffle(address, to = '0x7A856196100309143e1304C2b6F11363c6bFE9B9', price, participations, provider){
        price = Math.floor(price * participations * WEI_BASE_SELL)
        return ContractService.sendTransaction(price, address, provider, null, true, to);
    }
    static async cancelSell(assetId, address, provider){
        const optionsResponse = await ContractService.getPayloadForCancel( assetId, address);
        return ContractService.sendTransaction(null, address, provider, optionsResponse, false);
    }
    static async updatePrice(price, assetId, address, provider) {
        price = parseFloat(price.replace(',', '.'));
        const optionsResponse = await ContractService.getPayloadForUpdate(price, assetId, address);
        return ContractService.sendTransaction(price, address, provider, optionsResponse, false)

    }
    static async  noFunds(price, address, provider){
        price = Math.floor(price * WEI_BASE);
        const web3 = new Web3(provider);
        const balance = await web3.eth.getBalance(address);
        return price > parseInt(balance);
    }


    // -------------------------------Transactions
    static async sendTransaction(price, address, provider, payload, withPrice= true, to= null) {
        //TODO: generar obj con datos de la transaccion 
        const web3 = new Web3(provider);

        const options = payload? payload.data: null
        const nonce = await web3.eth.getTransactionCount(address, "pending");
        SessionStorageService.setNonce(nonce);
        to = options?options.to: to;
        to = to.toLocaleLowerCase();
        let transaction = {
            gasPrice: '0x3EF1480',
            gasLimit: '0x57E40',
            value: price && withPrice  ? price : 0,
            nonce,

            from: address,
            to,
        };
        if (options) {
            transaction.data= options.data;
        }


        return Promise.resolve(web3.eth.sendTransaction(transaction));

    }
    static async sendRawTransaction(transaction, web3) {
        //TODO: importar el eth para mandar la transaccion
    }

    static async getNonce(address, provider) {
        const web3 = new Web3(provider);

        const nonce = await web3.eth.getTransactionCount(address, "pending");
        return nonce;
    }

    //----------------------------------------------------------GET PAYLOADS ----------------------------------------------------------------
    static async getPayloadForSale(price, assetId, address) {
        return axios.get(`${functionsV1Base}setAssetPrice?&address=${address}&assetId=${assetId}&price=${price}`)
    }
    static async getPayloadForBuy(price, assetId, address) {
        return axios.get(`${functionsV1Base}getBuyPayload?&address=${address}&assetId=${assetId}&price=${price}`)
    }
    static async getPayloadForUpdate(price, assetId, address) {
        return axios.get(`${functionsV1Base}setAssetPrice?&address=${address}&assetId=${assetId}&price=${price}`)

    }
    static async getPayloadForCancel(assetId, address){
        return axios.get(`${functionsV1Base}getCancelPayload?&address=${address}&assetId=${assetId}`)

    }
}

export default ContractService;