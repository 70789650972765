import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container, Row, Col } from 'react-bootstrap'

export default function LoadingDetail() {
    return (
        <React.Fragment>
            <div style={{height:"50px"}}>
                <div className="m-1 fs-6 "></div>
            </div>
            <Container className="px-3 py-4"style={{minHeight:"100vh"}}>
                <Row>
                    <Col className="d-flex flex-col justify-content-center">
                        <Skeleton height={500} width={330}/>
                    </Col>
                    <Col style={window.screen.width > 990? {height: "430px"}:{height:"fit-content"}} className="my-4 d-flex flex-column justify-content-between">
                            <div>
                                <Skeleton/>
                                <h6 className="text-secondary"><Skeleton/></h6>
                                <p><Skeleton/></p>
                                <h6 className="text-secondary"><Skeleton/></h6>
                            </div>
                            <div className="bottom">
                                <p><Skeleton/></p>
                                <h3 className="text-secondary"><Skeleton/></h3>
                            </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
