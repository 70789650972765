import axios from 'axios';
import { lowercaseAddress} from '../utils/misc'
const functionsV1Base = process.env.REACT_APP_FUNCTION_V1_URL || 'https://us-central1-watafan-33650.cloudfunctions.net/';

export default class SyncService{

    static async  syncSell(address) {
        const res = await Promise.all([
            axios.get(`${functionsV1Base}syncMarketData`),
            axios.get(`${functionsV1Base}syncUserData?address=${lowercaseAddress(address)}`)


        ]);
        return Promise.resolve(res)
    }

    static async syncBuy(address, seller) {
        const res = await Promise.all([
            axios.get(`${functionsV1Base}syncMarketData`),
            axios.get(`${functionsV1Base}syncUserData?address=${lowercaseAddress(address)}`),
            axios.get(`${functionsV1Base}syncUserData?address=${lowercaseAddress(seller)}`)


        ]);
        return Promise.resolve(res);
    
    }

    static async syncPortfolio(address) {
        
        return axios.get(`${functionsV1Base}syncUserData?address=${lowercaseAddress(address)}`);

    }

    static async syncRaffle() {}

    static async setTransaction(address, type, nonce= "", data = {}){
        return axios.post(`${functionsV1Base}setCacheTransactions?address=${lowercaseAddress(address)}&type=${type}&nonce=${nonce}`, data);
    }
    static async getTransaction(address) {
        return axios.get(`${functionsV1Base}getPt?address=${lowercaseAddress(address)}`);
    }
    static async removeTransaction(address) {
        return axios.get(`${functionsV1Base}processPendingTransactions?address=${lowercaseAddress(address)}`);
    }
}