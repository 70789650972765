import React from 'react'
import Button from '../../common/Button/Button';
import {useHistory} from 'react-router-dom'
export default function RaffleActions({raffle, applicant}) {
    const history = useHistory();
    const goDetails= () => {
        //TODO: quitar estos join y split por una regexp
        history.push(`/raffles/${raffle.title.split(' ').join('_').split('?').join('+')}` , {data: raffle})
    }
    const onApply = () => (
        history.push(`/raffles/${raffle.title.split(' ').join('_').split('?').join('+')}`, { apply: (raffle.priceBtc == 0 ||false)})
    );
    return (
        <div className = "m-2">
            <Button variant="info" className="text-light m-1" style={{ width: "45%" }} disabled={raffle.priceBtc == 0 && applicant} onClick={onApply}>Participar</Button>
            <Button variant="info" className="text-light m-1" style = {{width: "45%"}} onClick={goDetails}>Ver Detalles</Button>
        </div>
    )
}
