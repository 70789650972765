import React from 'react';
import ReactDOM from 'react-dom';
import './Custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
if (window.location.href.includes('/#/watacards/')) {
  { window.location.href = `/marketplace/${window.location.href.split('/').reverse()[0]}` }
}
window.onbeforeunload = function (event) {
  var msg = 'Hay transacciones pendientes, estas seguro que quieres cerrar?'
  if (window.sessionStorage.getItem('transaction')) {
    var e = e || window.event;
    if (e) {
      e.returnValue = msg;
    }
    return msg
  }
};
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
